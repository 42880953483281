import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';
import { ProgramaCodigo } from '../../../constants/enums/programa-codigo.enum';
import { FILTRAR_VALORES_MAO_OBRA, GERAR_RELATORIO_VALORES_MAO_OBRA, LISTAR_VALORES_MAO_OBRA } from './constants';
import { obterValoresMaoObraAsync } from '../requests';
import {
  gerarRelatorioValoresMaoObraErro,
  gerarRelatorioValoresMaoObraSucesso,
  obterValoresMaoObraErro, 
  obterValoresMaoObraSucesso } from './actions';
import { SET_LIMIT_PAGE, SET_TABLE_ORDER } from '../../../redux/table/constants';
import { montarFiltroParaApi } from '../../../utils/api-filter';
import { gerarRelatorio } from '../../../services/requests/relatorio';
import { TipoRelatorio } from '../../../constants/enums/impressao.enum';

function* listarTodosValoresMaoObra() {
  try {
    const filtro = yield select((state) => state.ValoresMaoObra.filtros);
    const preferencias = yield select((state) => state.TableConfig[ProgramaCodigo.ValoresMaoObra]);

    let query = { filtro: montarFiltroParaApi(filtro) };

    if (preferencias) {
      query.ordenacao = {
        campo: preferencias?.ordenarPor || 'id',
        ordem: preferencias?.ordem?.toUpperCase() || 'ASC',
      };
      query.paginacao = {
        pagina: preferencias?.page || 1,
        limite: preferencias?.limit || 50,
      };
    }

    const { data } = yield call(obterValoresMaoObraAsync, query);
    yield put(obterValoresMaoObraSucesso(data));
  } catch (error) {
    yield put(obterValoresMaoObraErro(error.response?.data?.message || error.message));
  }
}
function* relatorioValoresMaoObra() {
  const { filtros, relatorio } = yield select((state) => state.ValoresMaoObra);
  const preferencias = yield select((state) => state.TableConfig[ProgramaCodigo.ValoresMaoObra]);
  const { modelo, tipo, comFiltro, colunas } = relatorio;
  const columns = colunas?.map((coluna) => coluna.value);
  let query = {};

  if (comFiltro) {
    query = {
      filtro: {
        ...montarFiltroParaApi(filtros),
        colunas: Array.isArray(columns) && columns?.length > 0 ? columns : undefined,
        tipo,
        modelo,
      },
      ordenacao: {
        campo: preferencias?.ordenarPor || 'id',
        ordem: preferencias?.ordem?.toUpperCase() || 'ASC',
      },
    };
  } else {
    query = {
      filtro: {
        colunas: Array.isArray(columns) && columns?.length > 0 ? columns : undefined,
        tipo,
        modelo,
      },
    };
  }

  try {
    const { data } = yield call(gerarRelatorio, ProgramaCodigo.ValoresMaoObra, query, tipo);

    if (tipo === TipoRelatorio.visualizar) {
      const url = URL.createObjectURL(
        new Blob([data], {
          type: 'application/pdf',
        }),
      );

      if (url) {
        yield put(gerarRelatorioValoresMaoObraSucesso());
        window.open(url, '_blank');
      }
    } else {
      yield put(
        gerarRelatorioValoresMaoObraSucesso({
          titulo: 'Pedido de impressão salvo.',
          mensagem: 'Seu relatório está sendo gerado.',
        }),
      );
    }
  } catch (e) {
    yield put(
      gerarRelatorioValoresMaoObraErro('Ocorreu um erro ao tentar gerar o relatório. Tente novamente mais tarde.'),
    );
  }
}


function* ordenarValoresMaoObra({ payload }) {
  if (payload.programaId === ProgramaCodigo.ValoresMaoObra) {
    yield call(listarTodosValoresMaoObra);
  }
}

function* mudarLimitePaginacao({ payload }) {
  if (payload.programaId === ProgramaCodigo.ValoresMaoObra) {
    yield call(listarTodosValoresMaoObra);
  }
}

export function* watchListarValoresMaoObra() {
  yield takeLatest(LISTAR_VALORES_MAO_OBRA, listarTodosValoresMaoObra);
}

export function* watchFiltrarValoresMaoObra() {
  yield takeLatest(FILTRAR_VALORES_MAO_OBRA, listarTodosValoresMaoObra);
}

export function* watchRelatorioValoresMaoObra() {
  yield takeLatest(GERAR_RELATORIO_VALORES_MAO_OBRA, relatorioValoresMaoObra);
}

export function* watchOrdenarValoresMaoObra() {
  yield takeLatest(SET_TABLE_ORDER, ordenarValoresMaoObra);
}

export function* watchLimitePaginacao() {
  yield takeLatest(SET_LIMIT_PAGE, mudarLimitePaginacao);
}

function* valoresMaoObraSaga() {
  yield all([
    fork(watchListarValoresMaoObra),
    fork(watchFiltrarValoresMaoObra),
    fork(watchRelatorioValoresMaoObra),
    fork(watchOrdenarValoresMaoObra),
    fork(watchLimitePaginacao),
  ]);
}

export default valoresMaoObraSaga;
