import Swal from 'sweetalert2';
import { filtroPadraoStatus } from '../../../constants/filtros';
import { 
  FILTRAR_PLANO_CONTAS, 
  GERAR_RELATORIO_PLANO_CONTAS,
  GERAR_RELATORIO_PLANO_CONTAS_ERRO,
  GERAR_RELATORIO_PLANO_CONTAS_SUCESSO,
  LISTAR_PLANO_CONTAS, 
  LISTAR_PLANO_CONTAS_ERRO, 
  LISTAR_PLANO_CONTAS_SUCESSO 
} from './constants';

const INIT_STATE = {
  planoContas: [],
  filtros: { ...filtroPadraoStatus },
  carregando: false,
  relatorio: {},
  gerandoRelatorio: false,
};

const PlanoContas = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LISTAR_PLANO_CONTAS:
      return { ...state, carregando: true };
    case FILTRAR_PLANO_CONTAS:
      return { ...state, filtros: action.payload, carregando: true };
    case LISTAR_PLANO_CONTAS_SUCESSO:
      return { ...state, planoContas: action.payload, carregando: false };
    case LISTAR_PLANO_CONTAS_ERRO:
      Swal.fire({
        icon: 'error',
        title: 'Erro ao buscar planos de conta',
        timer: 2000,
        text: action.payload,
      });
      return { ...state, planoContas: [], carregando: false };
      case GERAR_RELATORIO_PLANO_CONTAS:
      return { ...state, filtros: action.payload.filtros, relatorio: action.payload.relatorio, gerandoRelatorio: true };
    case GERAR_RELATORIO_PLANO_CONTAS_SUCESSO:
      if (action.payload) {
        Swal.fire({
          icon: 'success',
          title: action.payload.titulo,
          timer: 2000,
          text: action.payload.mensagem,
        });
      }      
      return { ...state, gerandoRelatorio: false };
    case GERAR_RELATORIO_PLANO_CONTAS_ERRO:
      Swal.fire({
        icon: 'error',
        title: 'Falha ao gerar Relatório',
        timer: 2000,
        text: action.payload,
      });
      return { ...state, gerandoRelatorio: false };
    default:
      return state;
  }
};

export default PlanoContas;
