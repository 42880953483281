import Swal from 'sweetalert2';
import { filtroPadraoStatus } from '../../../constants/filtros';
import {
  FILTRAR_VASILHAMES,
  GERAR_RELATORIO_VASILHAMES,
  GERAR_RELATORIO_VASILHAMES_ERRO,
  GERAR_RELATORIO_VASILHAMES_SUCESSO,
  LISTAR_VASILHAMES,
  LISTAR_VASILHAMES_ERRO,
  LISTAR_VASILHAMES_SUCESSO,
} from './constants';

const INIT_STATE = {
  itens: [],
  filtros: { ...filtroPadraoStatus },
  carregando: false,
  relatorio: {},
  gerandoRelatorio: false,
};

const VasilhamesReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LISTAR_VASILHAMES:
      return { ...state, carregando: true };
    case FILTRAR_VASILHAMES:
      return { ...state, filtros: action.payload, carregando: true };
    case LISTAR_VASILHAMES_SUCESSO:
      return {
        ...state,
        itens: action.payload,
        carregando: false,
      };
    case LISTAR_VASILHAMES_ERRO:
      Swal.fire({
        icon: 'error',
        title: 'Erro ao buscar vasilhames',
        timer: 2000,
        text: action.payload,
      });
      return { ...state, vasilhames: [], carregando: false };
    case GERAR_RELATORIO_VASILHAMES:
      return { ...state, filtros: action.payload.filtros, relatorio: action.payload.relatorio, gerandoRelatorio: true };
    case GERAR_RELATORIO_VASILHAMES_SUCESSO:
      if (action.payload) {
        Swal.fire({
          icon: 'success',
          title: action.payload.titulo,
          timer: 2000,
          text: action.payload.mensagem,
        });
      }
      return { ...state, gerandoRelatorio: false };
    case GERAR_RELATORIO_VASILHAMES_ERRO:
      Swal.fire({
        icon: 'error',
        title: 'Falha ao gerar Relatório',
        timer: 2000,
        text: action.payload,
      });
      return { ...state, gerandoRelatorio: false };
    default:
      return state;
  }
};

export default VasilhamesReducer;
