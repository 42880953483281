import Swal from 'sweetalert2';
import { filtroPadraoStatus } from '../../../constants/filtros';
import {
  FILTRAR_VARIEDADES,
  GERAR_RELATORIO_VARIEDADES,
  GERAR_RELATORIO_VARIEDADES_ERRO,
  GERAR_RELATORIO_VARIEDADES_SUCESSO,
  LISTAR_VARIEDADES,
  LISTAR_VARIEDADES_ERRO,
  LISTAR_VARIEDADES_SUCESSO,
} from './constants';

const INIT_STATE = {
  variedades: [],
  filtros: { ...filtroPadraoStatus },
  carregando: false,
  relatorio: {},
  gerandoRelatorio: false,
};

const VariedadesReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LISTAR_VARIEDADES:
      return { ...state, carregando: true };
    case FILTRAR_VARIEDADES:
      return { ...state, filtros: action.payload, carregando: true };
    case LISTAR_VARIEDADES_SUCESSO:
      return { ...state, variedades: action.payload, carregando: false };
    case LISTAR_VARIEDADES_ERRO:
      Swal.fire({
        icon: 'error',
        title: 'Erro ao buscar variedades',
        timer: 2000,
        text: action.payload,
      });
      return { ...state, variedades: [], carregando: false };
      case GERAR_RELATORIO_VARIEDADES:
        return { ...state, filtros: action.payload.filtros, relatorio: action.payload.relatorio, gerandoRelatorio: true };
      case GERAR_RELATORIO_VARIEDADES_SUCESSO:
        if (action.payload) {
          Swal.fire({
            icon: 'success',
            title: action.payload.titulo,
            timer: 2000,
            text: action.payload.mensagem,
          });
        }      
        return { ...state, gerandoRelatorio: false };
      case GERAR_RELATORIO_VARIEDADES_ERRO:
        Swal.fire({
          icon: 'error',
          title: 'Falha ao gerar Relatório',
          timer: 2000,
          text: action.payload,
        });
        return { ...state, gerandoRelatorio: false };
      default:
        return state;
  }
};

export default VariedadesReducer;
