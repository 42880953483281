import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';

import { ProgramaCodigo } from '../../../constants/enums/programa-codigo.enum';
import { FILTRAR_TAMANHO_PRODUTOS, GERAR_RELATORIO_TAMANHO_PRODUTOS, LISTAR_TAMANHO_PRODUTOS } from './constants';
import { obterTamanhoProdutosAsync } from '../requests';
import { 
  gerarRelatorioTamanhoProdutosErro,
  gerarRelatorioTamanhoProdutosSucesso,
  obterTamanhoProdutosErro, 
  obterTamanhoProdutosSucesso } from './actions';
import { SET_LIMIT_PAGE, SET_TABLE_ORDER } from '../../../redux/table/constants';
import { montarFiltroParaApi } from '../../../utils/api-filter';
import { gerarRelatorio } from '../../../services/requests/relatorio';
import { TipoRelatorio } from '../../../constants/enums/impressao.enum';

function* listarTodosTamanhoProdutos() {
  try {
    const filtro = yield select((state) => state.TamanhoProdutos.filtros);
    const preferencias = yield select((state) => state.TableConfig[ProgramaCodigo.TamanhoProdutos]);

    let query = { filtro: montarFiltroParaApi(filtro) };

    if (preferencias) {
      query.ordenacao = {
        campo: preferencias?.ordenarPor || 'tamanho',
        ordem: preferencias?.ordem?.toUpperCase() || 'ASC',
      };
      query.paginacao = {
        pagina: preferencias?.page || 1,
        limite: preferencias?.limit || 50,
      };
    }

    const { data } = yield call(obterTamanhoProdutosAsync, query);
    yield put(obterTamanhoProdutosSucesso(data));
  } catch (error) {
    yield put(obterTamanhoProdutosErro(error.response?.data?.message || error.message));
  }
}

function* relatorioTamanhoProdutos() {
  const { filtros, relatorio } = yield select((state) => state.TamanhoProdutos);
  const preferencias = yield select((state) => state.TableConfig[ProgramaCodigo.TamanhoProdutos]);
  const { modelo, tipo, comFiltro, colunas } = relatorio;
  const columns = colunas?.map((coluna) => coluna.value);
  let query = {};

  if (comFiltro) {
    query = {
      filtro: {
        ...montarFiltroParaApi(filtros),
        colunas: Array.isArray(columns) && columns?.length > 0 ? columns : undefined,
        tipo,
        modelo,
      },
      ordenacao: {
        campo: preferencias?.ordenarPor || 'tamanho',
        ordem: preferencias?.ordem?.toUpperCase() || 'ASC',
      },
    };
  } else {
    query = {
      filtro: {
        colunas: Array.isArray(columns) && columns?.length > 0 ? columns : undefined,
        tipo,
        modelo,
      },
    };
  }

  try {
    const { data } = yield call(gerarRelatorio, ProgramaCodigo.TamanhoProdutos, query, tipo);

    if (tipo === TipoRelatorio.visualizar) {
      const url = URL.createObjectURL(
        new Blob([data], {
          type: 'application/pdf',
        }),
      );

      if (url) {
        yield put(gerarRelatorioTamanhoProdutosSucesso());
        window.open(url, '_blank');
      }
    } else {
      yield put(
        gerarRelatorioTamanhoProdutosSucesso({
          titulo: 'Pedido de impressão salvo.',
          mensagem: 'Seu relatório está sendo gerado.',
        }),
      );
    }
  } catch (e) {
    yield put(
      gerarRelatorioTamanhoProdutosErro('Ocorreu um erro ao tentar gerar o relatório. Tente novamente mais tarde.'),
    );
  }
}


function* ordenarTamanhoProdutos({ payload }) {
  if (payload.programaId === ProgramaCodigo.TamanhoProdutos) {
    yield call(listarTodosTamanhoProdutos);
  }
}

function* mudarLimitePaginacao({ payload }) {
  if (payload.programaId === ProgramaCodigo.TamanhoProdutos) {
    yield call(listarTodosTamanhoProdutos);
  }
}

export function* watchListarTamanhoProdutos() {
  yield takeLatest(LISTAR_TAMANHO_PRODUTOS, listarTodosTamanhoProdutos);
}

export function* watchFiltrarTamanhoProdutos() {
  yield takeLatest(FILTRAR_TAMANHO_PRODUTOS, listarTodosTamanhoProdutos);
}

export function* watchRelatorioTamanhoProdutos() {
  yield takeLatest(GERAR_RELATORIO_TAMANHO_PRODUTOS, relatorioTamanhoProdutos);
}

export function* watchOrdenarTamanhoProdutos() {
  yield takeLatest(SET_TABLE_ORDER, ordenarTamanhoProdutos);
}

export function* watchLimitePaginacao() {
  yield takeLatest(SET_LIMIT_PAGE, mudarLimitePaginacao);
}

function* tamanhosProdutosSaga() {
  yield all([
    fork(watchListarTamanhoProdutos),
    fork(watchFiltrarTamanhoProdutos),
    fork(watchRelatorioTamanhoProdutos),
    fork(watchOrdenarTamanhoProdutos),
    fork(watchLimitePaginacao),
  ]);
}

export default tamanhosProdutosSaga;
