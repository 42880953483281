import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';
import { ProgramaCodigo } from '../../../constants/enums/programa-codigo.enum';
import {
  FILTRAR_MANUTENCAO_MAQUINAS_VEICULOS,
  LISTAR_MANUTENCAO_MAQUINAS_VEICULOS,
  GERAR_RELATORIO_MANUTENCAO_MAQUINAS_VEICULOS,
} from './constants';
import { obterManutencaoMaquinasVeiculosAsync } from '../requests';
import {
  obterManutencaoMaquinasVeiculosErro,
  gerarRelatorioManutencaoMaquinasVeiculosSucesso,
  gerarRelatorioManutencaoMaquinasVeiculosErro,
  obterManutencaoMaquinasVeiculosSucesso,
} from './actions';
import { SET_LIMIT_PAGE, SET_TABLE_ORDER } from '../../../redux/table/constants';
import { montarFiltroParaApi } from '../../../utils/api-filter';
import { gerarRelatorio } from '../../../services/requests/relatorio';
import { TipoRelatorio } from '../../../constants/enums/impressao.enum';

function* listarTodosManutencaoMaquinasVeiculos() {
  try {
    const filtro = yield select((state) => state.ManutencaoMaquinasVeiculos.filtros);
    const preferencias = yield select((state) => state.TableConfig[ProgramaCodigo.ManutencaoMaquinasVeiculos]);

    let query = { filtro: montarFiltroParaApi(filtro) };

    if (preferencias) {
      query.ordenacao = {
        campo: preferencias?.ordenarPor || 'dataCriacao',
        ordem: preferencias?.ordem?.toUpperCase() || 'ASC',
      };
      query.paginacao = {
        pagina: preferencias?.page || 1,
        limite: preferencias?.limit || 50,
      };
    }

    const { data } = yield call(obterManutencaoMaquinasVeiculosAsync, query);
    yield put(obterManutencaoMaquinasVeiculosSucesso(data));
  } catch (error) {
    yield put(obterManutencaoMaquinasVeiculosErro(error.response?.data?.message || error.message));
  }
}

function* relatorioManutencaoMaquinasVeiculos() {
  const { filtros, relatorio } = yield select((state) => state.ManutencaoMaquinasVeiculos);
  const preferencias = yield select((state) => state.TableConfig[ProgramaCodigo.ManutencaoMaquinasVeiculos]);
  const { modelo, tipo, comFiltro, colunas } = relatorio;
  const columns = colunas?.map((coluna) => coluna.value);
  let query = {};
  if (comFiltro) {
    query = {
      filtro: {
        ...montarFiltroParaApi(filtros),
        colunas: Array.isArray(columns) && columns?.length > 0 ? columns : undefined,
        tipo,
        modelo,
      },
      ordenacao: {
        campo: preferencias?.ordenarPor || 'dataCriacao',
        ordem: preferencias?.ordem?.toUpperCase() || 'ASC',
      },
    };
  } else {
    query = {
      filtro: {
        colunas: Array.isArray(columns) && columns?.length > 0 ? columns : undefined,
        tipo,
        modelo,
      },
    };
  }
  try {
    const { data } = yield call(gerarRelatorio, ProgramaCodigo.ManutencaoMaquinasVeiculos, query, tipo);

    if (tipo === TipoRelatorio.visualizar) {
      const url = URL.createObjectURL(
        new Blob([data], {
          type: 'application/pdf',
        }),
      );

      if (url) {
        yield put(gerarRelatorioManutencaoMaquinasVeiculosSucesso());
        window.open(url, '_blank');
      }
    } else {
      yield put(
        gerarRelatorioManutencaoMaquinasVeiculosSucesso({
          titulo: 'Pedido de impressão salvo.',
          mensagem: 'Seu relatório está sendo gerado.',
        }),
      );
    }
  } catch (e) {
    yield put(
      gerarRelatorioManutencaoMaquinasVeiculosErro(
        'Ocorreu um erro ao tentar gerar o relatório. Tente novamente mais tarde.',
      ),
    );
  }
}

function* ordenarManutencaoMaquinasVeiculos({ payload }) {
  if (payload.programaId === ProgramaCodigo.ManutencaoMaquinasVeiculos) {
    yield call(listarTodosManutencaoMaquinasVeiculos);
  }
}

function* mudarLimitePaginacao({ payload }) {
  if (payload.programaId === ProgramaCodigo.ManutencaoMaquinasVeiculos) {
    yield call(listarTodosManutencaoMaquinasVeiculos);
  }
}

export function* watchListarManutencaoMaquinasVeiculos() {
  yield takeLatest(LISTAR_MANUTENCAO_MAQUINAS_VEICULOS, listarTodosManutencaoMaquinasVeiculos);
}

export function* watchFiltrarManutencaoMaquinasVeiculos() {
  yield takeLatest(FILTRAR_MANUTENCAO_MAQUINAS_VEICULOS, listarTodosManutencaoMaquinasVeiculos);
}

export function* watchRelatorioManutencaoMaquinasVeiculos() {
  yield takeLatest(GERAR_RELATORIO_MANUTENCAO_MAQUINAS_VEICULOS, relatorioManutencaoMaquinasVeiculos);
}

export function* watchOrdenarManutencaoMaquinasVeiculos() {
  yield takeLatest(SET_TABLE_ORDER, ordenarManutencaoMaquinasVeiculos);
}

export function* watchLimitePaginacao() {
  yield takeLatest(SET_LIMIT_PAGE, mudarLimitePaginacao);
}

function* manutencaoMaquinasVeiculosSaga() {
  yield all([
    fork(watchListarManutencaoMaquinasVeiculos),
    fork(watchFiltrarManutencaoMaquinasVeiculos),
    fork(watchRelatorioManutencaoMaquinasVeiculos),
    fork(watchOrdenarManutencaoMaquinasVeiculos),
    fork(watchLimitePaginacao),
  ]);
}

export default manutencaoMaquinasVeiculosSaga;
