import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { State } from '../../redux/reducers';
import { SelectOption } from '../SelecaoAsync/SelecaoAsyncBase';
import { removerAcentos } from '../../utils/format';

interface SelecaoBaseProps {
  options: SelectOption[];
  value?: any;
  disabled?: boolean;
  multiple?: boolean;
  onChange?: (value: any) => void;
  isOptionDisabled?: (option: any, selectValue: ReadonlyArray<any>) => boolean;
  placeholder?: string;
  isClearable?: boolean;
  showSelectAllOption?: boolean;
  autoFocus?: boolean;
}

const selectAllOption: SelectOption = { value: 'select-all', label: 'Selecionar Todos' };

const SelecaoBase: React.FC<SelecaoBaseProps> = ({
  value,
  onChange,
  multiple,
  disabled,
  options,
  showSelectAllOption,
  autoFocus,
  ...props
}) => {
  const { coresTema } = useSelector((state: State) => state.Layout);
  const optionsAux = showSelectAllOption && multiple && options.length > 1 ? [selectAllOption, ...options] : options;
  const [filteredOptions, setFilteredOptions] = useState<SelectOption[]>([]);
  const selectRef = useRef<any>(null);

  const handleFilterOption = (option: SelectOption, inputValue: string) => {
    if (inputValue) {
      const labelFormatado = removerAcentos(option.label.toLowerCase());
      const inputValueFormatado = removerAcentos(inputValue.toLowerCase());

      return (
        labelFormatado.includes(inputValueFormatado) ||
        (filteredOptions.length > 1 && option.value === selectAllOption.value)
      );
    } else {
      return true;
    }
  };

  const handleInputChange = (inputValue: string, { action }) => {
    if (action === 'input-change') {
      if (inputValue) {
        const inputValueFormatado = removerAcentos(inputValue.toLowerCase());

        const filtered = optionsAux.filter((op) => {
          const labelFormatado = removerAcentos(op.label.toLowerCase());

          return labelFormatado.includes(inputValueFormatado);
        });

        setFilteredOptions(filtered);
      } else {
        setFilteredOptions([]);
      }
    }
  };

  const handleChange = (currentValues: SelectOption[], { option }) => {
    if (showSelectAllOption && multiple) {
      if (option?.value === selectAllOption.value) {
        if (filteredOptions.length > 0) {
          /* utiliza as options que vieram filtradas pelo input */

          /* remove a opção selecionar todos do currentValues */
          const filteredCurrentValues = currentValues?.filter((option) => option.value !== selectAllOption.value);
          /* remove registros duplicados caso venha no filtro e já esteja selecionado no combo  */
          const mergedFilteredOptions = filteredOptions.filter(
            (option) => !filteredCurrentValues.find((curr) => curr.value === option.value),
          );
          const options = filteredCurrentValues.concat(mergedFilteredOptions);

          setFilteredOptions([]);

          onChange && onChange(options);
        } else {
          /* utiliza as options que foram carregadas no 1º render */
          onChange && onChange(options);
        }
      } else {
        /* se selecionar uma opção normal */
        onChange && onChange(currentValues);
      }
    } else {
      /* se o combo não mostra a opção selecionar todos */
      onChange && onChange(currentValues);
    }
  };

  useEffect(() => {
    if (autoFocus && selectRef.current) {
      selectRef.current.focus();
    }
  }, [autoFocus]);

  return (
    <Select
      ref={selectRef}
      classNamePrefix="domper-select"
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary: coresTema.corBotaoPrimario,
          primary25: coresTema.corBotaoPrimario + '3D',
          primary50: coresTema.corBotaoPrimario + '5D',
        },
      })}
      isDisabled={disabled}
      placeholder="Selecione"
      loadingMessage={() => 'Carregando...'}
      noOptionsMessage={() => 'Sem registros'}
      options={optionsAux}
      onChange={handleChange}
      onInputChange={handleInputChange}
      filterOption={handleFilterOption}
      value={value}
      isMulti={!!multiple}
      menuPlacement={'auto'}
      menuPortalTarget={document.body}
      styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
      closeMenuOnScroll={(e) => ['drawer-container', 'wrapper'].includes(e.target.id)}
      {...props}
    />
  );
};

export default SelecaoBase;
