import Swal from 'sweetalert2';
import { filtroPadraoStatus } from '../../../constants/filtros';
import { 
  FILTRAR_MODELOS_ETIQUETA, 
  GERAR_RELATORIO_MODELOS_ETIQUETA,
  GERAR_RELATORIO_MODELOS_ETIQUETA_ERRO,
  GERAR_RELATORIO_MODELOS_ETIQUETA_SUCESSO,
  LISTAR_MODELOS_ETIQUETA, 
  LISTAR_MODELOS_ETIQUETA_ERRO, 
  LISTAR_MODELOS_ETIQUETA_SUCESSO } from './constants';

const INIT_STATE = {
  modelosEtiquetas: [],
  filtros: { ...filtroPadraoStatus },
  carregando: false,
  relatorio: {},
  gerandoRelatorio: false,
};

const ModelosEtiquetasReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LISTAR_MODELOS_ETIQUETA:
      return { ...state, carregando: true };
    case FILTRAR_MODELOS_ETIQUETA:
      return { ...state, filtros: action.payload, carregando: true };
    case LISTAR_MODELOS_ETIQUETA_SUCESSO:
      return { ...state, modelosEtiquetas: action.payload, carregando: false };
    case LISTAR_MODELOS_ETIQUETA_ERRO:
      Swal.fire({
        icon: 'error',
        title: 'Erro ao buscar Modelos de Etiquetas',
        timer: 2000,
        text: action.payload,
      });
      return { ...state, modelosEtiquetas: [], carregando: false };
      case GERAR_RELATORIO_MODELOS_ETIQUETA:
        return { ...state, filtros: action.payload.filtros, relatorio: action.payload.relatorio, gerandoRelatorio: true };
      case GERAR_RELATORIO_MODELOS_ETIQUETA_SUCESSO:
        if (action.payload) {
          Swal.fire({
            icon: 'success',
            title: action.payload.titulo,
            timer: 2000,
            text: action.payload.mensagem,
          });
        }      
        return { ...state, gerandoRelatorio: false };
      case GERAR_RELATORIO_MODELOS_ETIQUETA_ERRO:
        Swal.fire({
          icon: 'error',
          title: 'Falha ao gerar Relatório',
          timer: 2000,
          text: action.payload,
        });
        return { ...state, gerandoRelatorio: false };
      default:
        return state;
  }
};

export default ModelosEtiquetasReducer;
