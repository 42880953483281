import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';

import { ProgramaCodigo } from '../../../constants/enums/programa-codigo.enum';
import { FILTRAR_GRUPO_PRODUTO, GERAR_RELATORIO_GRUPO_PRODUTO, LISTAR_GRUPO_PRODUTO } from './constants';
import { obterGruposProdutosAsync } from '../requests';
import {
  gerarRelatorioGrupoProdutoErro,
  gerarRelatorioGrupoProdutoSucesso,
  obterGrupoProdutoErro,
  obterGrupoProdutoSucesso,
} from './actions';
import { SET_LIMIT_PAGE, SET_TABLE_ORDER } from '../../../redux/table/constants';
import { montarFiltroParaApi } from '../../../utils/api-filter';
import { gerarRelatorio } from '../../../services/requests/relatorio';
import { TipoRelatorio } from '../../../constants/enums/impressao.enum';

function* listarTodosGrupoProduto() {
  try {
    const filtro = yield select((state) => state.GrupoProduto.filtros);
    const preferencias = yield select((state) => state.TableConfig[ProgramaCodigo.GrupoProduto]);

    let query = { filtro: montarFiltroParaApi(filtro) };

    if (preferencias) {
      query.ordenacao = {
        campo: preferencias?.ordenarPor || 'nome',
        ordem: preferencias?.ordem?.toUpperCase() || 'ASC',
      };
      query.paginacao = {
        pagina: preferencias?.page || 1,
        limite: preferencias?.limit || 50,
      };
    }

    const { data } = yield call(obterGruposProdutosAsync, query);
    yield put(obterGrupoProdutoSucesso(data));
  } catch (error) {
    yield put(obterGrupoProdutoErro(error.response?.data?.message || error.message));
  }
}

function* relatorioGrupoProduto() {
  const { filtros, relatorio } = yield select((state) => state.GrupoProduto);
  const preferencias = yield select((state) => state.TableConfig[ProgramaCodigo.GrupoProduto]);
  const { modelo, tipo, comFiltro, colunas } = relatorio;
  const columns = colunas?.map((coluna) => coluna.value);
  let query = {};

  if (comFiltro) {
    query = {
      filtro: {
        ...montarFiltroParaApi(filtros),
        colunas: Array.isArray(columns) && columns?.length > 0 ? columns : undefined,
        tipo,
        modelo,
      },
      ordenacao: {
        campo: preferencias?.ordenarPor || 'nome',
        ordem: preferencias?.ordem?.toUpperCase() || 'ASC',
      },
    };
  } else {
    query = {
      filtro: {
        colunas: Array.isArray(columns) && columns?.length > 0 ? columns : undefined,
        tipo,
        modelo,
      },
    };
  }

  try {
    const { data } = yield call(gerarRelatorio, ProgramaCodigo.GrupoProduto, query, tipo);

    if (tipo === TipoRelatorio.visualizar) {
      const url = URL.createObjectURL(
        new Blob([data], {
          type: 'application/pdf',
        }),
      );

      if (url) {
        yield put(gerarRelatorioGrupoProdutoSucesso());
        window.open(url, '_blank');
      }
    } else {
      yield put(
        gerarRelatorioGrupoProdutoSucesso({
          titulo: 'Pedido de impressão salvo.',
          mensagem: 'Seu relatório está sendo gerado.',
        }),
      );
    }
  } catch (e) {
    yield put(
      gerarRelatorioGrupoProdutoErro('Ocorreu um erro ao tentar gerar o relatório. Tente novamente mais tarde.'),
    );
  }
}

function* ordenarGrupoProduto({ payload }) {
  if (payload.programaId === ProgramaCodigo.GrupoProduto) {
    yield call(listarTodosGrupoProduto);
  }
}

function* mudarLimitePaginacao({ payload }) {
  if (payload.programaId === ProgramaCodigo.GrupoProduto) {
    yield call(listarTodosGrupoProduto);
  }
}

export function* watchListarGrupoProduto() {
  yield takeLatest(LISTAR_GRUPO_PRODUTO, listarTodosGrupoProduto);
}

export function* watchFiltrarGrupoProduto() {
  yield takeLatest(FILTRAR_GRUPO_PRODUTO, listarTodosGrupoProduto);
}

export function* watchRelatorioGrupoProduto() {
  yield takeLatest(GERAR_RELATORIO_GRUPO_PRODUTO, relatorioGrupoProduto);
}

export function* watchOrdenarGrupoProduto() {
  yield takeLatest(SET_TABLE_ORDER, ordenarGrupoProduto);
}

export function* watchLimitePaginacao() {
  yield takeLatest(SET_LIMIT_PAGE, mudarLimitePaginacao);
}

function* GrupoProdutoSaga() {
  yield all([
    fork(watchListarGrupoProduto),
    fork(watchFiltrarGrupoProduto),
    fork(watchRelatorioGrupoProduto),
    fork(watchOrdenarGrupoProduto),
    fork(watchLimitePaginacao),
  ]);
}

export default GrupoProdutoSaga;
