import Swal from 'sweetalert2';

import {
  FILTRAR_ORDENS_COMPRA,
  LISTAR_ORDENS_COMPRA,
  LISTAR_ORDENS_COMPRA_ERRO,
  LISTAR_ORDENS_COMPRA_SUCESSO,
  GERAR_RELATORIO_ORDENS_COMPRA,
  GERAR_RELATORIO_ORDENS_COMPRA_ERRO,
  GERAR_RELATORIO_ORDENS_COMPRA_SUCESSO,
} from './constants';

const INIT_STATE = {
  itens: [],
  filtros: {},
  carregando: false,
  relatorio: {},
  gerandoRelatorio: false,
};

const OrdensCompra = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LISTAR_ORDENS_COMPRA:
      return { ...state, carregando: true };
    case FILTRAR_ORDENS_COMPRA:
      return { ...state, filtros: action.payload, carregando: true };
    case LISTAR_ORDENS_COMPRA_SUCESSO:
      return { ...state, itens: action.payload, carregando: false };
    case LISTAR_ORDENS_COMPRA_ERRO:
      Swal.fire({
        icon: 'error',
        title: 'Erro ao buscar Ordens de Compra',
        timer: 2000,
        text: action.payload,
      });
      return { ...state, itens: [], carregando: false };
    case GERAR_RELATORIO_ORDENS_COMPRA:
      return { ...state, filtros: action.payload.filtros, relatorio: action.payload.relatorio, gerandoRelatorio: true };
    case GERAR_RELATORIO_ORDENS_COMPRA_SUCESSO:
      if (action.payload) {
        Swal.fire({
          icon: 'success',
          title: action.payload.titulo,
          timer: 2000,
          text: action.payload.mensagem,
        });
      }
      return { ...state, gerandoRelatorio: false };
    case GERAR_RELATORIO_ORDENS_COMPRA_ERRO:
      Swal.fire({
        icon: 'error',
        title: 'Falha ao gerar Relatório',
        timer: 2000,
        text: action.payload,
      });
      return { ...state, gerandoRelatorio: false };
    default:
      return state;
  }
};

export default OrdensCompra;
