import { IConfigNfeConsulta, IDadosFiscaisEmpresa, IEmpresaConsulta } from './interfaces';
import api from '../../services/api';
import { montarQueryString } from '../../utils';
import { AxiosResponse } from 'axios';
import { ICidadeConsulta } from '../cidades/interfaces';

export const obterEmpresa = (id: number) => {
  return api.request<IEmpresaConsulta>({
    url: `empresa/${id}`,
    method: 'GET',
  });
};

export const obterEmpresasAsync = (query = {}) => {
  const querystr = montarQueryString(query);
  return api.request<{ result: IEmpresaConsulta[]; total: number }>({
    url: `empresa${querystr}`,
    method: 'GET',
  });
};

export const gerarProximoCodigoEmpresaAsync = (organizacaoId: number): Promise<AxiosResponse<number>> => {
  return api.request({
    url: `empresa/gerar-proximo-codigo/${organizacaoId}`,
    method: 'GET',
  });
};

export const cadastrarEmpresa = (values, certificado: File | undefined) => {
  const formData = new FormData();
  formData.append('empresa', JSON.stringify(values));
  if (certificado) {
    formData.append('certificado', certificado);
  }

  return api.request<IEmpresaConsulta>({
    url: `empresa`,
    method: 'POST',
    data: formData,
  });
};

export const editarEmpresa = (id, values, certificado: File | undefined) => {
  const formData = new FormData();
  formData.append('empresa', JSON.stringify(values));
  if (certificado) {
    formData.append('certificado', certificado);
  }

  return api.request<IEmpresaConsulta>({
    url: `empresa/${id}`,
    method: 'PATCH',
    data: formData,
  });
};

export const obterEmpresasRegimeTributario = () => {
  return api.request({
    url: `/empresa/enum/regime_tributario`,
    method: 'GET',
  });
};

export const obterTiposContatoAsync = () => {
  return api.request({
    url: '/empresa/enum/tipo_contato',
    method: 'GET',
  });
};

export const buscarNaReceitaPorCNPJ = (cnpj) => {
  cnpj = cnpj?.replace(/\D/g, '');
  return api.request({
    url: `/empresa/receita/cnpj/${cnpj}`,
    method: 'GET',
  });
};

export const buscarNaReceitaPorCPF = (cpf) => {
  cpf = cpf?.replace(/\D/g, '');
  return api.request({
    url: `/empresa/receita/cpf/${cpf}`,
    method: 'GET',
  });
};

export const buscarEnderecoPorCepAsync = (cep) => {
  cep = cep.replace(/\D/g, '');
  return api.request({
    url: `/endereco/buscar_por_cep/${cep}`,
    method: 'GET',
  });
};

export const buscarConfigNfeEmpresaLogadaAsync = () => {
  return api.request<IConfigNfeConsulta>({
    url: `/empresa/buscar-config-nfe`,
    method: 'GET',
  });
};

export const buscarDadosFiscaisEmpresaLogada = (empresaId: number) => {
  return api.request<IDadosFiscaisEmpresa>({
    url: `/empresa/buscar-dados-fiscais/${empresaId}`,
    method: 'GET',
  });
};

export const buscarCidadeEmpresaLogada = async (empresaId: number): Promise<ICidadeConsulta> => {
  const { data } = await api.request<ICidadeConsulta>({
    url: `/empresa/buscar-cidade/${empresaId}`,
    method: 'GET',
  });
  return data;
};

export const removerEmpresasEmMassaAsync = (ids: number[]): Promise<AxiosResponse<{ affected: number }>> => {
  return api.request({
    url: `empresa`,
    data: { ids },
    method: 'DELETE',
  });
};

export const removerEmpresaAsync = (id: number): Promise<AxiosResponse<{ affected: number }>> =>
  api.request({
    url: `empresa/${id}`,
    method: 'DELETE',
  });
