import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';
import { ProgramaCodigo } from '../../../constants/enums/programa-codigo.enum';
import { SET_LIMIT_PAGE, SET_TABLE_ORDER } from '../../../redux/table/constants';
import { FILTRAR_OPCOES_CADASTRAIS, GERAR_RELATORIO_OPCOES_CADASTRAIS ,LISTAR_OPCOES_CADASTRAIS } from './constants';
import { obterOpcoesCadastraisAsync } from '../requests';
import { 
  gerarRelatorioOpcoesCadastraisErro,
  gerarRelatorioOpcoesCadastraisSucesso,
  obterOpcoesCadastraisErro, obterOpcoesCadastraisSucesso } from './actions';
import { montarFiltroParaApi } from '../../../utils/api-filter';
import { gerarRelatorio } from '../../../services/requests/relatorio';
import { TipoRelatorio } from '../../../constants/enums/impressao.enum';

function* listarTodosOpcoesCadastrais() {
  try {
    const filtro = yield select((state) => state.OpcoesCadastrais.filtros);
    const preferencias = yield select((state) => state.TableConfig[ProgramaCodigo.OpcoesCadastrais]);

    let query = { filtro: montarFiltroParaApi(filtro) };

    if (preferencias) {
      query.ordenacao = {
        campo: preferencias?.ordenarPor || 'nome',
        ordem: preferencias?.ordem?.toUpperCase() || 'ASC',
      };
      query.paginacao = {
        pagina: preferencias?.page || 1,
        limite: preferencias?.limit || 50,
      };
    }

    const { data } = yield call(obterOpcoesCadastraisAsync, query);
    yield put(obterOpcoesCadastraisSucesso(data));
  } catch (error) {
    yield put(obterOpcoesCadastraisErro(error.response?.data?.message || error.message));
  }
}

function* relatorioOpcoesCadastrais() {
  const { filtros, relatorio } = yield select((state) => state.OpcoesCadastrais);
  const preferencias = yield select((state) => state.TableConfig[ProgramaCodigo.OpcoesCadastrais]);
  const { modelo, tipo, comFiltro, colunas } = relatorio;
  const columns = colunas?.map((coluna) => coluna.value);
  let query = {};

  if (comFiltro) {
    query = {
      filtro: {
        ...montarFiltroParaApi(filtros),
        colunas: Array.isArray(columns) && columns?.length > 0 ? columns : undefined,
        tipo,
        modelo,
      },
      ordenacao: {
        campo: preferencias?.ordenarPor || 'nome',
        ordem: preferencias?.ordem?.toUpperCase() || 'ASC',
      },
    };
  } else {
    query = {
      filtro: {
        colunas: Array.isArray(columns) && columns?.length > 0 ? columns : undefined,
        tipo,
        modelo,
      },
    };
  }

  try {
    const { data } = yield call(gerarRelatorio, ProgramaCodigo.OpcoesCadastrais, query, tipo);

    if (tipo === TipoRelatorio.visualizar) {
      const url = URL.createObjectURL(
        new Blob([data], {
          type: 'application/pdf',
        }),
      );

      if (url) {
        yield put(gerarRelatorioOpcoesCadastraisSucesso());
        window.open(url, '_blank');
      }
    } else {
      yield put(
        gerarRelatorioOpcoesCadastraisSucesso({
          titulo: 'Pedido de impressão salvo.',
          mensagem: 'Seu relatório está sendo gerado.',
        }),
      );
    }
  } catch (e) {
    yield put(
      gerarRelatorioOpcoesCadastraisErro('Ocorreu um erro ao tentar gerar o relatório. Tente novamente mais tarde.'),
    );
  }
}


function* ordenarOpcoesCadastrais({ payload }) {
  if (payload.programaId === ProgramaCodigo.OpcoesCadastrais) {
    yield call(listarTodosOpcoesCadastrais);
  }
}

function* mudarLimitePaginacao({ payload }) {
  if (payload.programaId === ProgramaCodigo.OpcoesCadastrais) {
    yield call(listarTodosOpcoesCadastrais);
  }
}

export function* watchListarOpcoesCadastrais() {
  yield takeLatest(LISTAR_OPCOES_CADASTRAIS, listarTodosOpcoesCadastrais);
}

export function* watchFiltrarOpcoesCadastrais() {
  yield takeLatest(FILTRAR_OPCOES_CADASTRAIS, listarTodosOpcoesCadastrais);
}

export function* watchRelatorioOpcoesCadastrais() {
  yield takeLatest(GERAR_RELATORIO_OPCOES_CADASTRAIS, relatorioOpcoesCadastrais);
}

export function* watchOrdenarOpcoesCadastrais() {
  yield takeLatest(SET_TABLE_ORDER, ordenarOpcoesCadastrais);
}

export function* watchLimitePaginacao() {
  yield takeLatest(SET_LIMIT_PAGE, mudarLimitePaginacao);
}

function* opcoesCadastraisSaga() {
  yield all([
    fork(watchListarOpcoesCadastrais),
    fork(watchFiltrarOpcoesCadastrais),
    fork(watchRelatorioOpcoesCadastrais),
    fork(watchOrdenarOpcoesCadastrais),
    fork(watchLimitePaginacao),
  ]);
}

export default opcoesCadastraisSaga;
