import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';
import { ProgramaCodigo } from '../../../constants/enums/programa-codigo.enum';
import { SET_LIMIT_PAGE, SET_TABLE_ORDER } from '../../../redux/table/constants';
import { FILTRAR_ARMADILHAS, GERAR_RELATORIO_ARMADILHAS, LISTAR_ARMADILHAS } from './constants';
import { obterArmadilhasAsync } from '../requests';
import { 
  gerarRelatorioArmadilhasErro,
  gerarRelatorioArmadilhasSucesso,
  obterArmadilhasErro, 
  obterArmadilhasSucesso } from './actions';
import { montarFiltroParaApi } from '../../../utils/api-filter';
import { gerarRelatorio } from '../../../services/requests/relatorio';
import { TipoRelatorio } from '../../../constants/enums/impressao.enum';

function* listarTodosArmadilhas() {
  try {
    const filtro = yield select((state) => state.Armadilhas.filtros);
    const preferencias = yield select((state) => state.TableConfig[ProgramaCodigo.Armadilhas]);

    let query = { filtro: montarFiltroParaApi(filtro) };

    if (preferencias) {
      query.ordenacao = {
        campo: preferencias?.ordenarPor || 'nome',
        ordem: preferencias?.ordem?.toUpperCase() || 'ASC',
      };
      query.paginacao = {
        pagina: preferencias?.page || 1,
        limite: preferencias?.limit || 50,
      };
    }

    const { data } = yield call(obterArmadilhasAsync, query);
    yield put(obterArmadilhasSucesso(data));
  } catch (error) {
    yield put(obterArmadilhasErro(error.response?.data?.message || error.message));
  }
}

function* relatorioArmadilhas() {
  const { filtros, relatorio } = yield select((state) => state.Armadilhas);
  const preferencias = yield select((state) => state.TableConfig[ProgramaCodigo.Armadilhas]);
  const { modelo, tipo, comFiltro, colunas } = relatorio;
  const columns = colunas?.map((coluna) => coluna.value);
  let query = {};

  if (comFiltro) {
    query = {
      filtro: {
        ...montarFiltroParaApi(filtros),
        colunas: Array.isArray(columns) && columns?.length > 0 ? columns : undefined,
        tipo,
        modelo,
      },
      ordenacao: {
        campo: preferencias?.ordenarPor || 'nome',
        ordem: preferencias?.ordem?.toUpperCase() || 'ASC',
      },
    };
  } else {
    query = {
      filtro: {
        colunas: Array.isArray(columns) && columns?.length > 0 ? columns : undefined,
        tipo,
        modelo,
      },
    };
  }

  try {
    const { data } = yield call(gerarRelatorio, ProgramaCodigo.Armadilhas, query, tipo);

    if (tipo === TipoRelatorio.visualizar) {
      const url = URL.createObjectURL(
        new Blob([data], {
          type: 'application/pdf',
        }),
      );

      if (url) {
        yield put(gerarRelatorioArmadilhasSucesso());
        window.open(url, '_blank');
      }
    } else {
      yield put(
        gerarRelatorioArmadilhasSucesso({
          titulo: 'Pedido de impressão salvo.',
          mensagem: 'Seu relatório está sendo gerado.',
        }),
      );
    }
  } catch (e) {
    yield put(
      gerarRelatorioArmadilhasErro('Ocorreu um erro ao tentar gerar o relatório. Tente novamente mais tarde.'),
    );
  }
}


function* ordenarArmadilhas({ payload }) {
  if (payload.programaId === ProgramaCodigo.Armadilhas) {
    yield call(listarTodosArmadilhas);
  }
}

function* mudarLimitePaginacao({ payload }) {
  if (payload.programaId === ProgramaCodigo.Armadilhas) {
    yield call(listarTodosArmadilhas);
  }
}

export function* watchListarArmadilhas() {
  yield takeLatest(LISTAR_ARMADILHAS, listarTodosArmadilhas);
}

export function* watchFiltrarArmadilhas() {
  yield takeLatest(FILTRAR_ARMADILHAS, listarTodosArmadilhas);
}

export function* watchRelatorioArmadilhas() {
  yield takeLatest(GERAR_RELATORIO_ARMADILHAS, relatorioArmadilhas);
}

export function* watchOrdenarArmadilhas() {
  yield takeLatest(SET_TABLE_ORDER, ordenarArmadilhas);
}

export function* watchLimitePaginacao() {
  yield takeLatest(SET_LIMIT_PAGE, mudarLimitePaginacao);
}

function* armadilhasSaga() {
  yield all([
    fork(watchListarArmadilhas),
    fork(watchFiltrarArmadilhas),
    fork(watchRelatorioArmadilhas),
    fork(watchOrdenarArmadilhas),
    fork(watchLimitePaginacao),
  ]);
}

export default armadilhasSaga;
