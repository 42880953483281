import Swal from 'sweetalert2';
import {
  FILTRAR_TRANSFERENCIAS_CAIXA_BANCOS,
  GERAR_RELATORIO_TRANSFERENCIAS_CAIXA_BANCOS,
  GERAR_RELATORIO_TRANSFERENCIAS_CAIXA_BANCOS_ERRO,
  GERAR_RELATORIO_TRANSFERENCIAS_CAIXA_BANCOS_SUCESSO,
  LISTAR_TRANSFERENCIAS_CAIXA_BANCOS,
  LISTAR_TRANSFERENCIAS_CAIXA_BANCOS_ERRO,
  LISTAR_TRANSFERENCIAS_CAIXA_BANCOS_SUCESSO,
} from './constants';

const INIT_STATE = {
  transferenciasCaixaBancos: [],
  filtros: { },
  carregando: false,
  relatorio: {},
  gerandoRelatorio: false,
};

const TransferenciasCaixaBancosReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LISTAR_TRANSFERENCIAS_CAIXA_BANCOS:
      return { ...state, carregando: true };
    case FILTRAR_TRANSFERENCIAS_CAIXA_BANCOS:
      return { ...state, filtros: action.payload, carregando: true };
    case LISTAR_TRANSFERENCIAS_CAIXA_BANCOS_SUCESSO:
      return { ...state, transferenciasCaixaBancos: action.payload, carregando: false };
    case LISTAR_TRANSFERENCIAS_CAIXA_BANCOS_ERRO:
      Swal.fire({
        icon: 'error',
        title: 'Erro ao buscar Transferências entre Caixa e Bancos',
        timer: 2000,
        text: action.payload,
      });
      return { ...state, transferenciasCaixaBancos: [], carregando: false };
      case GERAR_RELATORIO_TRANSFERENCIAS_CAIXA_BANCOS:
        return { ...state, filtros: action.payload.filtros, relatorio: action.payload.relatorio, gerandoRelatorio: true };
      case GERAR_RELATORIO_TRANSFERENCIAS_CAIXA_BANCOS_SUCESSO:
        if (action.payload) {
          Swal.fire({
            icon: 'success',
            title: action.payload.titulo,
            timer: 2000,
            text: action.payload.mensagem,
          });
        }      
        return { ...state, gerandoRelatorio: false };
      case GERAR_RELATORIO_TRANSFERENCIAS_CAIXA_BANCOS_ERRO:
        Swal.fire({
          icon: 'error',
          title: 'Falha ao gerar Relatório',
          timer: 2000,
          text: action.payload,
        });
        return { ...state, gerandoRelatorio: false };
      default:
        return state;
  }
};

export default TransferenciasCaixaBancosReducer;
