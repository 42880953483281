import { RuleObject } from 'rc-field-form/es/interface';

export const NumberRangeInputValidator = {
  validator(_, value) {
    if (!value) return Promise.resolve();
    if (Number(value[0]) > Number(value[1]))
      return Promise.reject(new Error('Número Inicial deve ser menor que Número Final'));
    if ((value[0] === '' && value[1]) || (value[1] === '' && value[0]))
      return Promise.reject(new Error('Preecha os 2 campos'));

    return Promise.resolve();
  },
};

export const DateRangeInputValidator = {
  validator(_, value) {
    if (!value) return Promise.resolve();
    if (value[1] && value[0] > value[1]) return Promise.reject(new Error('Data Inicial deve ser menor que Data Final'));

    return Promise.resolve();
  },
};

export const DateRangeInputFullValidator = {
  validator(rule, value) {
    if (!rule.required && !value) {
      return Promise.resolve();
    }
    if (rule.required && !value) {
      return Promise.reject(new Error('Data Inicial e Final é obrigatório'));
    }
    if (value[1] && value[0] > value[1]) {
      return Promise.reject(new Error('Data Inicial deve ser menor que Data Final'));
    }
    if ((value[0] === '' && value[1]) || (value[1] === '' && value[0])) {
      return Promise.reject(new Error('Preecha as duas datas'));
    }

    return Promise.resolve();
  },
};

export const ieValidator = (rule: RuleObject, value: string) => {
  if (rule.required && !value) {
    return Promise.reject(new Error('Inscrição Estadual é obrigatório'));
  }
  if (rule.max && value.length > rule.max) {
    return Promise.reject(new Error(`Inscrição Estadual não pode ser maior que ${rule.max}`));
  }

  const ie = require('inscricaoestadual');
  if (value && !ie(value)) {
    return Promise.reject(new Error('Inscrição Estadual é inválida'));
  }

  return Promise.resolve();
};
