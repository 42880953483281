import api from '../../services/api';
import { AxiosResponse } from 'axios';
import { IContratoCadastro, IContratoConsulta } from './interfaces';
import { montarQueryString } from '../../utils';
import { IContratoOcorrenciaCadastro } from './cadastro/ocorrencia/interfaces';

export const obterContratosAsync = (
  query,
): Promise<
  AxiosResponse<{ result: IContratoConsulta[]; total: number }>
> => {
  const queryStr = montarQueryString(query);

  return api.request({
    url: `contratos${queryStr}`,
    method: 'GET',
  });
};

export const obterContratoAsync = (
  id: number,
): Promise<AxiosResponse<IContratoConsulta>> => {
  return api.request({
    url: `contratos/${id}`,
    method: 'GET',
  });
};

export const contratoOcorrenciasAsync = (
  id: number,
  ocorrencia: IContratoOcorrenciaCadastro[],
): Promise<AxiosResponse<void>> => {
  return api.request({
    url: `contratos/ocorrencia/${id}`,
    data: ocorrencia,
    method: 'PATCH',
  });
};

export const situacaoContratoAsync = (
  id: number,
  contrato: IContratoCadastro,
): Promise<AxiosResponse<void>> => {
  return api.request({
    url: `contratos/situacao/${id}`,
    data: contrato,
    method: 'PATCH',
  });
};

export const obterMovimentacoesAsync = (id: number): Promise<AxiosResponse<IContratoConsulta>> => {
  return api.request({
    url: `contratos/obter-movimentacoes/${id}`,
    method: 'GET',
  });
};

export const obterServicosAsync = (id: number): Promise<AxiosResponse<IContratoConsulta>> => {
  return api.request({
    url: `contratos/obter-servicos/${id}`,
    method: 'GET',
  });
};

export const gerarProximoCodigoContratoAsync = (): Promise<
  AxiosResponse<number>
> => {
  return api.request({
    url: `contratos/gerar-proximo-codigo`,
    method: 'GET',
  });
};

export const criarContratosAsync = (
  contrato: IContratoCadastro,
): Promise<AxiosResponse<IContratoConsulta>> => {
  return api.request({
    url: `contratos`,
    data: contrato,
    method: 'POST',
  });
};

export const atualizarContratosAsync = (
  id: number,
  contrato: IContratoCadastro,
): Promise<AxiosResponse<IContratoConsulta>> => {
  return api.request({
    url: `contratos/${id}`,
    data: contrato,
    method: 'PATCH',
  });
};

export const removerContratosEmMassaAsync = (
  ids: number[],
): Promise<AxiosResponse<{ affected: number }>> => {
  return api.request({
    url: `contratos`,
    data: { ids },
    method: 'DELETE',
  });
};

export const removerContratosAsync = (
  id: number,
): Promise<AxiosResponse<{ affected: number }>> =>
  api.request({
    url: `contratos/${id}`,
    method: 'DELETE',
  });
