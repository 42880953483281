import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';

import { ProgramaCodigo } from '../../../constants/enums/programa-codigo.enum';
import { SET_LIMIT_PAGE, SET_TABLE_ORDER } from '../../../redux/table/constants';
import { FILTRAR_ESTADOS, GERAR_RELATORIO_ESTADOS, LISTAR_ESTADOS } from './constants';
import { obterEstadosAsync } from '../requests';
import { 
  gerarRelatorioEstadosErro,
  gerarRelatorioEstadosSucesso,
  obterEstadosErro, 
  obterEstadosSucesso } from './actions';
import { montarFiltroParaApi } from '../../../utils/api-filter';
import { gerarRelatorio } from '../../../services/requests/relatorio';
import { TipoRelatorio } from '../../../constants/enums/impressao.enum';

function* listarTodosEstados() {
  try {
    const filtro = yield select((state) => state.Estados.filtros);
    const preferencias = yield select((state) => state.TableConfig[ProgramaCodigo.Estado]);

    let query = { filtro: montarFiltroParaApi(filtro) };

    if (preferencias) {
      query.ordenacao = {
        campo: preferencias?.ordenarPor || 'nome',
        ordem: preferencias?.ordem?.toUpperCase() || 'ASC',
      };
      query.paginacao = {
        pagina: preferencias?.page || 1,
        limite: preferencias?.limit || 50,
      };
    }

    const { data } = yield call(obterEstadosAsync, query);
    yield put(obterEstadosSucesso(data));
  } catch (error) {
    yield put(obterEstadosErro(error.response?.data?.message || error.message));
  }
}

function* relatorioEstados() {
  const { filtros, relatorio } = yield select((state) => state.Estados);
  const preferencias = yield select((state) => state.TableConfig[ProgramaCodigo.Estado]);
  const { modelo, tipo, comFiltro, colunas } = relatorio;
  const columns = colunas?.map((coluna) => coluna.value);
  let query = {};

  if (comFiltro) {
    query = {
      filtro: {
        ...montarFiltroParaApi(filtros),
        colunas: Array.isArray(columns) && columns?.length > 0 ? columns : undefined,
        tipo,
        modelo,
      },
      ordenacao: {
        campo: preferencias?.ordenarPor || 'nome',
        ordem: preferencias?.ordem?.toUpperCase() || 'ASC',
      },
    };
  } else {
    query = {
      filtro: {
        colunas: Array.isArray(columns) && columns?.length > 0 ? columns : undefined,
        tipo,
        modelo,
      },
    };
  }

  try {
    const { data } = yield call(gerarRelatorio, ProgramaCodigo.Estado, query, tipo);

    if (tipo === TipoRelatorio.visualizar) {
      const url = URL.createObjectURL(
        new Blob([data], {
          type: 'application/pdf',
        }),
      );

      if (url) {
        yield put(gerarRelatorioEstadosSucesso());
        window.open(url, '_blank');
      }
    } else {
      yield put(
        gerarRelatorioEstadosSucesso({
          titulo: 'Pedido de impressão salvo.',
          mensagem: 'Seu relatório está sendo gerado.',
        }),
      );
    }
  } catch (e) {
    yield put(
      gerarRelatorioEstadosErro('Ocorreu um erro ao tentar gerar o relatório. Tente novamente mais tarde.'),
    );
  }
}


function* ordenarEstados({ payload }) {
  if (payload.programaId === ProgramaCodigo.Estado) {
    yield call(listarTodosEstados);
  }
}

function* mudarLimitePaginacao({ payload }) {
  if (payload.programaId === ProgramaCodigo.Estado) {
    yield call(listarTodosEstados);
  }
}

export function* watchListarEstados() {
  yield takeLatest(LISTAR_ESTADOS, listarTodosEstados);
}

export function* watchFiltrarEstados() {
  yield takeLatest(FILTRAR_ESTADOS, listarTodosEstados);
}

export function* watchRelatorioEstados() {
  yield takeLatest(GERAR_RELATORIO_ESTADOS, relatorioEstados);
}

export function* watchOrdenarEstados() {
  yield takeLatest(SET_TABLE_ORDER, ordenarEstados);
}

export function* watchLimitePaginacao() {
  yield takeLatest(SET_LIMIT_PAGE, mudarLimitePaginacao);
}

function* estadoSaga() {
  yield all([
    fork(watchListarEstados),
    fork(watchFiltrarEstados),
    fork(watchRelatorioEstados),
    fork(watchOrdenarEstados),
    fork(watchLimitePaginacao),
  ]);
}

export default estadoSaga;
