import Swal from 'sweetalert2';
import { filtroPadraoStatus } from '../../../constants/filtros';
import { 
  FILTRAR_MOTIVOS_CANCELAMENTOS_CONTRATOS, 
  GERAR_RELATORIO_MOTIVOS_CANCELAMENTOS_CONTRATOS,
  GERAR_RELATORIO_MOTIVOS_CANCELAMENTOS_CONTRATOS_ERRO,
  GERAR_RELATORIO_MOTIVOS_CANCELAMENTOS_CONTRATOS_SUCESSO,
  LISTAR_MOTIVOS_CANCELAMENTOS_CONTRATOS, 
  LISTAR_MOTIVOS_CANCELAMENTOS_CONTRATOS_ERRO, 
  LISTAR_MOTIVOS_CANCELAMENTOS_CONTRATOS_SUCESSO 
} from './constants';

const INIT_STATE = {
  motivosCancelamentosContratos: [],
  filtros: { ...filtroPadraoStatus },
  carregando: false,
  relatorio: {},
  gerandoRelatorio: false,
};

const MotivosCancelamentosContratos = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LISTAR_MOTIVOS_CANCELAMENTOS_CONTRATOS:
      return { ...state, carregando: true };
    case FILTRAR_MOTIVOS_CANCELAMENTOS_CONTRATOS:
      return { ...state, filtros: action.payload, carregando: true };
    case LISTAR_MOTIVOS_CANCELAMENTOS_CONTRATOS_SUCESSO:
      return { ...state, motivosCancelamentosContratos: action.payload, carregando: false };
    case LISTAR_MOTIVOS_CANCELAMENTOS_CONTRATOS_ERRO:
      Swal.fire({
        icon: 'error',
        title: 'Erro ao buscar Motivos de Cancelamento de Contratos',
        timer: 2000,
        text: action.payload,
      });
      return { ...state, motivosCancelamentosContratos: [], carregando: false };
      case GERAR_RELATORIO_MOTIVOS_CANCELAMENTOS_CONTRATOS:
      return { ...state, filtros: action.payload.filtros, relatorio: action.payload.relatorio, gerandoRelatorio: true };
    case GERAR_RELATORIO_MOTIVOS_CANCELAMENTOS_CONTRATOS_SUCESSO:
      if (action.payload) {
        Swal.fire({
          icon: 'success',
          title: action.payload.titulo,
          timer: 2000,
          text: action.payload.mensagem,
        });
      }      
      return { ...state, gerandoRelatorio: false };
    case GERAR_RELATORIO_MOTIVOS_CANCELAMENTOS_CONTRATOS_ERRO:
      Swal.fire({
        icon: 'error',
        title: 'Falha ao gerar Relatório',
        timer: 2000,
        text: action.payload,
      });
      return { ...state, gerandoRelatorio: false };
    default:
      return state;
  }
};

export default MotivosCancelamentosContratos;
