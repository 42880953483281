import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';

import { ProgramaCodigo } from '../../../constants/enums/programa-codigo.enum';
import {
  FILTRAR_RECOMENDACOES_TECNICAS,
  LISTAR_RECOMENDACOES_TECNICAS,
  GERAR_RELATORIO_RECOMENDACOES_TECNICAS,
  GERAR_IMPRESSAO_RECOMENDACOES_TECNICAS,
} from './constants';
import { obterRecomendacoesTecnicasAsync } from '../requests';
import {
  obterRecomendacoesTecnicasErro,
  obterRecomendacoesTecnicasSucesso,
  gerarRelatorioRecomendacoesTecnicasErro,
  gerarRelatorioRecomendacoesTecnicasSucesso,
} from './actions';
import { SET_LIMIT_PAGE, SET_TABLE_ORDER } from '../../../redux/table/constants';
import { montarFiltroParaApi } from '../../../utils/api-filter';
import { gerarRelatorio } from '../../../services/requests/relatorio';
import { TipoRelatorio } from '../../../constants/enums/impressao.enum';
import { ModelosRelatorioRecomendacaoTecnica } from '../constants';

function* listarTodosRecomendacoesTecnicas() {
  try {
    const filtro = yield select((state) => state.RecomendacoesTecnicas.filtros);
    const preferencias = yield select((state) => state.TableConfig[ProgramaCodigo.RecomendacoesTecnicas]);

    let query = { filtro: montarFiltroParaApi(filtro) };

    if (preferencias) {
      query.ordenacao = {
        campo: preferencias?.ordenarPor || 'dataInicial',
        ordem: preferencias?.ordem?.toUpperCase() || 'DESC',
      };
      query.paginacao = {
        pagina: preferencias?.page || 1,
        limite: preferencias?.limit || 50,
      };
    }

    const { data } = yield call(obterRecomendacoesTecnicasAsync, query);
    yield put(obterRecomendacoesTecnicasSucesso(data));
  } catch (error) {
    yield put(obterRecomendacoesTecnicasErro(error.response?.data?.message || error.message));
  }
}

function* impressaoRecomendacoesTecnicas({ payload: id }) {
  let tipo = TipoRelatorio.visualizar;
  let modelo = ModelosRelatorioRecomendacaoTecnica.Individual;

  const columns = '';
  let query = {};
  query = {
    filtro: {
      recomendacaoId: id,
      colunas: Array.isArray(columns) && columns?.length > 0 ? columns : undefined,
      tipo,
      modelo,
    },
  };
  try {
    const { data } = yield call(gerarRelatorio, ProgramaCodigo.RecomendacoesTecnicas, query, tipo);

    if (tipo === TipoRelatorio.visualizar) {
      const url = URL.createObjectURL(
        new Blob([data], {
          type: 'application/pdf',
        }),
      );

      if (url) {
        yield put(gerarRelatorioRecomendacoesTecnicasSucesso());
        window.open(url, '_blank');
      }
    }
  } catch (e) {
    yield put(
      gerarRelatorioRecomendacoesTecnicasErro(
        'Ocorreu um erro ao tentar gerar o relatório. Tente novamente mais tarde.',
      ),
    );
  }
}

function* relatorioRecomendacoesTecnicas() {
  const { filtros, relatorio } = yield select((state) => state.RecomendacoesTecnicas);
  const preferencias = yield select((state) => state.TableConfig[ProgramaCodigo.RecomendacoesTecnicas]);
  const { modelo, tipo, comFiltro, colunas } = relatorio;
  const columns = colunas?.map((coluna) => coluna.value);
  let query = {};

  if (comFiltro) {
    query = {
      filtro: {
        ...montarFiltroParaApi(filtros),
        colunas: Array.isArray(columns) && columns?.length > 0 ? columns : undefined,
        tipo,
        modelo,
      },
      ordenacao: {
        campo: preferencias?.ordenarPor || 'id',
        ordem: preferencias?.ordem?.toUpperCase() || 'ASC',
      },
    };
  } else {
    query = {
      filtro: {
        colunas: Array.isArray(columns) && columns?.length > 0 ? columns : undefined,
        tipo,
        modelo,
      },
    };
  }

  try {
    const { data } = yield call(gerarRelatorio, ProgramaCodigo.RecomendacoesTecnicas, query, tipo);

    if (tipo === TipoRelatorio.visualizar) {
      const url = URL.createObjectURL(
        new Blob([data], {
          type: 'application/pdf',
        }),
      );

      if (url) {
        yield put(gerarRelatorioRecomendacoesTecnicasSucesso());
        window.open(url, '_blank');
      }
    } else {
      yield put(
        gerarRelatorioRecomendacoesTecnicasSucesso({
          titulo: 'Pedido de impressão salvo.',
          mensagem: 'Seu relatório está sendo gerado.',
        }),
      );
    }
  } catch (e) {
    yield put(gerarRelatorioRecomendacoesTecnicasErro('Ocorreu um erro ao tentar gerar o relatório. Tente novamente mais tarde.'));
  }
}

function* ordenarRecomendacoesTecnicas({ payload }) {
  if (payload.programaId === ProgramaCodigo.RecomendacoesTecnicas) {
    yield call(listarTodosRecomendacoesTecnicas);
  }
}

function* mudarLimitePaginacao({ payload }) {
  if (payload.programaId === ProgramaCodigo.RecomendacoesTecnicas) {
    yield call(listarTodosRecomendacoesTecnicas);
  }
}

export function* watchListarRecomendacoesTecnicas() {
  yield takeLatest(LISTAR_RECOMENDACOES_TECNICAS, listarTodosRecomendacoesTecnicas);
}

export function* watchFiltrarRecomendacoesTecnicas() {
  yield takeLatest(FILTRAR_RECOMENDACOES_TECNICAS, listarTodosRecomendacoesTecnicas);
}

export function* watchOrdenarRecomendacoesTecnicas() {
  yield takeLatest(SET_TABLE_ORDER, ordenarRecomendacoesTecnicas);
}

export function* watchLimitePaginacao() {
  yield takeLatest(SET_LIMIT_PAGE, mudarLimitePaginacao);
}

export function* watchImpressaoRecomendacoesTecnicas() {
  yield takeLatest(GERAR_IMPRESSAO_RECOMENDACOES_TECNICAS, impressaoRecomendacoesTecnicas);
}

export function* watchRelatorioRecomendacoesTecnicas() {
  yield takeLatest(GERAR_RELATORIO_RECOMENDACOES_TECNICAS, relatorioRecomendacoesTecnicas);
}

function* recomendacoesTecnicasSaga() {
  yield all([
    fork(watchListarRecomendacoesTecnicas),
    fork(watchFiltrarRecomendacoesTecnicas),
    fork(watchOrdenarRecomendacoesTecnicas),
    fork(watchLimitePaginacao),
    fork(watchRelatorioRecomendacoesTecnicas),
    fork(watchImpressaoRecomendacoesTecnicas),
  ]);
}

export default recomendacoesTecnicasSaga;
