import Swal from 'sweetalert2';
import {
  FILTRAR_MESAS_COMANDAS,
  GERAR_RELATORIO_MESAS_COMANDAS,
  GERAR_RELATORIO_MESAS_COMANDAS_ERRO,
  GERAR_RELATORIO_MESAS_COMANDAS_SUCESSO,
  LISTAR_MESAS_COMANDAS,
  LISTAR_MESAS_COMANDAS_ERRO,
  LISTAR_MESAS_COMANDAS_SUCESSO,
} from './constants';

const INIT_STATE = {
  mesasComandas: [],
  filtros:  {},
  carregando: false,
  relatorio: {},
  gerandoRelatorio: false,
};

const MesasComandasReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LISTAR_MESAS_COMANDAS:
      return { ...state, carregando: true };
    case FILTRAR_MESAS_COMANDAS:
      return { ...state, filtros: action.payload, carregando: true };
    case LISTAR_MESAS_COMANDAS_SUCESSO:
      return { ...state, mesasComandas: action.payload, carregando: false };
    case LISTAR_MESAS_COMANDAS_ERRO:
      Swal.fire({
        icon: 'error',
        title: 'Erro ao buscar alíquotas de Impostos de Serviços',
        timer: 2000,
        text: action.payload,
      });
      return { ...state, mesasComandas: [], carregando: false };
      case GERAR_RELATORIO_MESAS_COMANDAS:
        return { ...state, filtros: action.payload.filtros, relatorio: action.payload.relatorio, gerandoRelatorio: true };
      case GERAR_RELATORIO_MESAS_COMANDAS_SUCESSO:
        if (action.payload) {
          Swal.fire({
            icon: 'success',
            title: action.payload.titulo,
            timer: 2000,
            text: action.payload.mensagem,
          });
        }      
        return { ...state, gerandoRelatorio: false };
      case GERAR_RELATORIO_MESAS_COMANDAS_ERRO:
        Swal.fire({
          icon: 'error',
          title: 'Falha ao gerar Relatório',
          timer: 2000,
          text: action.payload,
        });
        return { ...state, gerandoRelatorio: false };
      default:
        return state;
  }
};

export default MesasComandasReducer;
