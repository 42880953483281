import Swal from 'sweetalert2';
import { filtroPadraoStatus } from '../../../constants/filtros';
import {
  FILTRAR_DEPARTAMENTO_PRODUTO,
  GERAR_RELATORIO_DEPARTAMENTO_PRODUTO,
  GERAR_RELATORIO_DEPARTAMENTO_PRODUTO_ERRO,
  GERAR_RELATORIO_DEPARTAMENTO_PRODUTO_SUCESSO,
  LISTAR_DEPARTAMENTO_PRODUTO,
  LISTAR_DEPARTAMENTO_PRODUTO_ERRO,
  LISTAR_DEPARTAMENTO_PRODUTO_SUCESSO,
} from './constants';

const INIT_STATE = {
  departamentoProduto: {
    result: [],
    total: 0,
  },
  filtros: { ...filtroPadraoStatus },
  carregando: false,
  relatorio: {},
  gerandoRelatorio: false,
};

const grupoProdutoReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case FILTRAR_DEPARTAMENTO_PRODUTO:
      return { ...state, filtros: action.payload, carregando: true };
    case LISTAR_DEPARTAMENTO_PRODUTO:
      return { ...state, carregando: true };
    case LISTAR_DEPARTAMENTO_PRODUTO_SUCESSO:
      return { ...state, departamentoProduto: action.payload, carregando: false };
    case LISTAR_DEPARTAMENTO_PRODUTO_ERRO:
      Swal.fire({
        icon: 'error',
        title: 'Erro ao buscar departamentos de produtos',
        timer: 2000,
        text: action.payload,
      });
      return { ...state, departamentoProduto: [], carregando: false };
      case GERAR_RELATORIO_DEPARTAMENTO_PRODUTO:
        return { ...state, filtros: action.payload.filtros, relatorio: action.payload.relatorio, gerandoRelatorio: true };
      case GERAR_RELATORIO_DEPARTAMENTO_PRODUTO_SUCESSO:
        if (action.payload) {
          Swal.fire({
            icon: 'success',
            title: action.payload.titulo,
            timer: 2000,
            text: action.payload.mensagem,
          });
        }      
        return { ...state, gerandoRelatorio: false };
      case GERAR_RELATORIO_DEPARTAMENTO_PRODUTO_ERRO:
        Swal.fire({
          icon: 'error',
          title: 'Falha ao gerar Relatório',
          timer: 2000,
          text: action.payload,
        });
        return { ...state, gerandoRelatorio: false };
      default:
        return state;
  }
};

export default grupoProdutoReducer;
