import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';
import { ProgramaCodigo } from '../../../constants/enums/programa-codigo.enum';
import { SET_LIMIT_PAGE, SET_TABLE_ORDER } from '../../../redux/table/constants';
import {
  FILTRAR_TRANSFERENCIA_DEPOSITOS,
  GERAR_RELATORIO_TRANSFERENCIA_DEPOSITOS,
  LISTAR_TRANSFERENCIA_DEPOSITOS,
} from './constants';
import { obterTransferenciasDepositosAsync } from '../requests';
import {
  gerarRelatorioTransferenciaDepositoErro,
  gerarRelatorioTransferenciaDepositoSucesso,
  obterTransferenciaDepositoErro,
  obterTransferenciaDepositoSucesso,
} from './actions';
import { montarFiltroParaApi } from '../../../utils/api-filter';
import { gerarRelatorio } from '../../../services/requests/relatorio';
import { TipoRelatorio } from '../../../constants/enums/impressao.enum';

function* listarTodosTransferenciaDeposito() {
  try {
    const filtro = yield select((state) => state.TransferenciaDeposito.filtros);
    const preferencias = yield select((state) => state.TableConfig[ProgramaCodigo.TransferenciasDepositos]);

    let query = { filtro: montarFiltroParaApi(filtro) };

    if (preferencias) {
      query.ordenacao = {
        campo: preferencias?.ordenarPor || 'id',
        ordem: preferencias?.ordem?.toUpperCase() || 'ASC',
      };
      query.paginacao = {
        pagina: preferencias?.page || 1,
        limite: preferencias?.limit || 50,
      };
    }

    const { data } = yield call(obterTransferenciasDepositosAsync, query);
    yield put(obterTransferenciaDepositoSucesso(data));
  } catch (error) {
    yield put(obterTransferenciaDepositoErro(error.response?.data?.message || error.message));
  }
}

function* relatorioTransferenciaDeposito() {
  const { filtros, relatorio } = yield select((state) => state.TransferenciaDeposito);
  const preferencias = yield select((state) => state.TableConfig[ProgramaCodigo.TransferenciasDepositos]);
  const { modelo, tipo, comFiltro, colunas } = relatorio;
  const columns = colunas?.map((coluna) => coluna.value);
  let query = {};

  if (comFiltro) {
    query = {
      filtro: {
        ...montarFiltroParaApi(filtros),
        colunas: Array.isArray(columns) && columns?.length > 0 ? columns : undefined,
        tipo,
        modelo,
      },
      ordenacao: {
        campo: preferencias?.ordenarPor || 'id',
        ordem: preferencias?.ordem?.toUpperCase() || 'ASC',
      },
    };
  } else {
    query = {
      filtro: {
        colunas: Array.isArray(columns) && columns?.length > 0 ? columns : undefined,
        tipo,
        modelo,
      },
    };
  }

  try {
    const { data } = yield call(gerarRelatorio, ProgramaCodigo.TransferenciasDepositos, query, tipo);

    if (tipo === TipoRelatorio.visualizar) {
      const url = URL.createObjectURL(
        new Blob([data], {
          type: 'application/pdf',
        }),
      );

      if (url) {
        yield put(gerarRelatorioTransferenciaDepositoSucesso());
        window.open(url, '_blank');
      }
    } else {
      yield put(
        gerarRelatorioTransferenciaDepositoSucesso({
          titulo: 'Pedido de impressão salvo.',
          mensagem: 'Seu relatório está sendo gerado.',
        }),
      );
    }
  } catch (e) {
    yield put(
      gerarRelatorioTransferenciaDepositoErro(
        'Ocorreu um erro ao tentar gerar o relatório. Tente novamente mais tarde.',
      ),
    );
  }
}

function* ordenarTransferenciaDeposito({ payload }) {
  if (payload.programaId === ProgramaCodigo.TransferenciasDepositos) {
    yield call(listarTodosTransferenciaDeposito);
  }
}

function* mudarLimitePaginacao({ payload }) {
  if (payload.programaId === ProgramaCodigo.TransferenciasDepositos) {
    yield call(listarTodosTransferenciaDeposito);
  }
}

export function* watchListarTransferenciaDeposito() {
  yield takeLatest(LISTAR_TRANSFERENCIA_DEPOSITOS, listarTodosTransferenciaDeposito);
}

export function* watchFiltrarTransferenciaDeposito() {
  yield takeLatest(FILTRAR_TRANSFERENCIA_DEPOSITOS, listarTodosTransferenciaDeposito);
}

export function* watchRelatorioTransferenciaDeposito() {
  yield takeLatest(GERAR_RELATORIO_TRANSFERENCIA_DEPOSITOS, relatorioTransferenciaDeposito);
}

export function* watchOrdenarTransferenciaDeposito() {
  yield takeLatest(SET_TABLE_ORDER, ordenarTransferenciaDeposito);
}

export function* watchLimitePaginacao() {
  yield takeLatest(SET_LIMIT_PAGE, mudarLimitePaginacao);
}

function* TransferenciaDepositoSaga() {
  yield all([
    fork(watchListarTransferenciaDeposito),
    fork(watchFiltrarTransferenciaDeposito),
    fork(watchRelatorioTransferenciaDeposito),
    fork(watchOrdenarTransferenciaDeposito),
    fork(watchLimitePaginacao),
  ]);
}

export default TransferenciaDepositoSaga;
