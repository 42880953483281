import Swal from 'sweetalert2';
import { GERAR_RELATORIO_ESTOQUE, GERAR_RELATORIO_ESTOQUE_ERRO, GERAR_RELATORIO_ESTOQUE_SUCESSO } from './constants';

const INIT_STATE = {
  filtros: {},
  relatorio: {},
  gerandoRelatorio: false,
};

const RelatoriosEstoqueReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GERAR_RELATORIO_ESTOQUE:
      return { ...state, filtros: action.payload.filtros, relatorio: action.payload.relatorio, gerandoRelatorio: true };
    case GERAR_RELATORIO_ESTOQUE_SUCESSO:
      if (action.payload) {
        Swal.fire({
          icon: 'success',
          title: action.payload.titulo,
          timer: 2000,
          text: action.payload.mensagem,
        });
      }
      return { ...state, gerandoRelatorio: false };
    case GERAR_RELATORIO_ESTOQUE_ERRO:
      Swal.fire({
        icon: 'error',
        title: 'Falha ao gerar Relatório',
        timer: 2000,
        text: action.payload,
      });
      return { ...state, gerandoRelatorio: false };
    default:
      return state;
  }
};

export default RelatoriosEstoqueReducer;
