import { AxiosResponse } from 'axios';
import api from '../../services/api';
import { IProdutoBaseCadastro, IProdutoBaseConsulta, IProdutoCadastro, IProdutoConsulta } from './interfaces';
import { montarQueryString } from '../../utils';

export const obterProdutosBaseAsync = (
  query,
): Promise<AxiosResponse<{ result: IProdutoBaseConsulta[]; total: number }>> => {
  const queryStr = montarQueryString(query);

  return api.request({
    url: `produtos-base${queryStr}`,
    method: 'GET',
  });
};

export const obterProdutoBaseAsync = (id: number): Promise<AxiosResponse<IProdutoBaseConsulta>> => {
  return api.request({
    url: `produtos-base/${id}`,
    method: 'GET',
  });
};

export const uploadImagensProduto = (id: number, imagens: File[]) => {
  const formData = new FormData();
  imagens.forEach((imagem) => {
    formData.append('imagens', imagem);
  });

  return api.request({
    url: `produtos-base/${id}/upload`,
    method: 'POST',
    data: formData,
  });
};

export const deletarImagemProdutoAsync = (id: number, url: string) => {
  return api.request({
    url: `produtos-base/imagem/${id}`,
    method: 'DELETE',
    data: {
      url,
    },
  });
};

export const gerarProximoCodigoProdutoBaseAsync = (): Promise<AxiosResponse<number>> => {
  return api.request({
    url: `produtos-base/gerar-proximo-codigo`,
    method: 'GET',
  });
};

export const criarProdutoBaseAsync = (
  sistemaProducao: IProdutoBaseCadastro,
): Promise<AxiosResponse<IProdutoBaseConsulta>> => {
  return api.request({
    url: `produtos-base`,
    data: sistemaProducao,
    method: 'POST',
  });
};

export const atualizarProdutoBaseAsync = (
  id: number,
  sistemaProducao: IProdutoBaseCadastro,
): Promise<AxiosResponse<IProdutoBaseConsulta>> => {
  return api.request({
    url: `produtos-base/${id}`,
    data: sistemaProducao,
    method: 'PATCH',
  });
};

export const criarProdutoAsync = (sistemaProducao: IProdutoCadastro): Promise<AxiosResponse<IProdutoConsulta>> => {
  return api.request({
    url: `produtos`,
    data: sistemaProducao,
    method: 'POST',
  });
};

export const atualizarProdutoAsync = (
  id: number,
  sistemaProducao: IProdutoCadastro,
): Promise<AxiosResponse<IProdutoConsulta>> => {
  return api.request({
    url: `produtos/${id}`,
    data: sistemaProducao,
    method: 'PATCH',
  });
};

export const removerProdutoEmMassaAsync = (ids: number[]): Promise<AxiosResponse<{ affected: number }>> => {
  return api.request({
    url: `produtos-base`,
    data: { ids },
    method: 'DELETE',
  });
};

export const removerProdutoBaseAsync = (id: number): Promise<AxiosResponse<{ affected: number }>> =>
  api.request({
    url: `produtos-base/${id}`,
    method: 'DELETE',
  });
