import { IMenu } from '../../interfaces/menu';
import { PermissaoCodigo } from '../enums/permissao-codigo.enum';
import { ProgramaCodigo } from '../enums/programa-codigo.enum';
import { RoleCodigo } from '../enums/role-codigo.enum';

export const FaturamentoMenus: IMenu = {
  roles: [RoleCodigo.ClienteUser, RoleCodigo.ClienteAdmin],
  name: 'Faturamento',
  children: [
    {
      programaCod: ProgramaCodigo.NotasFiscais,
      name: 'Notas Fiscais',
      path: '/notas-fiscais/listar',
      component: 'listarNotasFiscais',
      children: [
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/notas-fiscais/listar',
          component: 'listarNotasFiscais',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/notas-fiscais/criar',
          component: 'notasFiscais',
        },
        {
          permissaoCod: PermissaoCodigo.Alterar,
          path: '/notas-fiscais/editar/:id',
          component: 'notasFiscais',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/notas-fiscais/visualizar/:id',
          component: 'notasFiscais',
        },
      ],
    },
    {
      programaCod: ProgramaCodigo.NotasFiscaisConsumidor,
      name: 'Notas Fiscais Consumidor',
      path: '/notas-fiscais-consumidor/listar',
      component: 'listarNotasFiscaisConsumidor',
      children: [
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/notas-fiscais-consumidor/criar',
          component: 'notasFiscaisConsumidor',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/notas-fiscais-consumidor/listar',
          component: 'listarNotasFiscaisConsumidor',
        },  
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/notas-fiscais-consumidor/visualizar/:id',
          component: 'notasFiscaisConsumidor',
        },
      ],
    },
    {
      programaCod: ProgramaCodigo.NotasFiscaisServicos,
      name: 'Notas Fiscais de Serviço',
      path: '/notas-fiscais-servico/listar',
      component: 'listarNotasFiscaisServicos',
      children: [
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/notas-fiscais-servico/listar',
          component: 'listarNotasFiscaisServicos',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/notas-fiscais-servico/criar',
          component: 'notasFiscaisServicos',
        },
        {
          permissaoCod: PermissaoCodigo.Alterar,
          path: '/notas-fiscais-servico/editar/:id',
          component: 'notasFiscaisServicos',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/notas-fiscais-servico/visualizar/:id',
          component: 'notasFiscaisServicos',
        },
      ],
    },
  ],
};
