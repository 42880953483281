import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';

import { ProgramaCodigo } from '../../../constants/enums/programa-codigo.enum';
import { FILTRAR_INFORMACOES_NUTRICIONAIS, GERAR_RELATORIO_INFORMACOES_NUTRICIONAIS, LISTAR_INFORMACOES_NUTRICIONAIS } from './constants';
import { obterInformacoesNutricionaisAsync } from '../requests';
import { 
  gerarRelatorioInformacoesNutricionaisErro,
  gerarRelatorioInformacoesNutricionaisSucesso,
  obterInformacoesNutricionaisErro, 
  obterInformacoesNutricionaisSucesso } from './actions';
import { SET_LIMIT_PAGE, SET_TABLE_ORDER } from '../../../redux/table/constants';
import { montarFiltroParaApi } from '../../../utils/api-filter';
import { gerarRelatorio } from '../../../services/requests/relatorio';
import { TipoRelatorio } from '../../../constants/enums/impressao.enum';

function* listarTodosInformacoesNutricionais() {
  try {
    const filtro = yield select((state) => state.InformacoesNutricionais.filtros);
    const preferencias = yield select((state) => state.TableConfig[ProgramaCodigo.InformacoesNutricionais]);

    let query = { filtro: montarFiltroParaApi(filtro) };

    if (preferencias) {
      query.ordenacao = {
        campo: preferencias?.ordenarPor || 'nome',
        ordem: preferencias?.ordem?.toUpperCase() || 'ASC',
      };
      query.paginacao = {
        pagina: preferencias?.page || 1,
        limite: preferencias?.limit || 50,
      };
    }
    const { data } = yield call(obterInformacoesNutricionaisAsync, query);
    yield put(obterInformacoesNutricionaisSucesso(data));
  } catch (error) {
    yield put(obterInformacoesNutricionaisErro(error.response?.data?.message || error.message));
  }
}

function* relatorioInformacoesNutricionais() {
  const { filtros, relatorio } = yield select((state) => state.InformacoesNutricionais);
  const preferencias = yield select((state) => state.TableConfig[ProgramaCodigo.InformacoesNutricionais]);
  const { modelo, tipo, comFiltro, colunas } = relatorio;
  const columns = colunas?.map((coluna) => coluna.value);
  let query = {};

  if (comFiltro) {
    query = {
      filtro: {
        ...montarFiltroParaApi(filtros),
        colunas: Array.isArray(columns) && columns?.length > 0 ? columns : undefined,
        tipo,
        modelo,
      },
      ordenacao: {
        campo: preferencias?.ordenarPor || 'nome',
        ordem: preferencias?.ordem?.toUpperCase() || 'ASC',
      },
    };
  } else {
    query = {
      filtro: {
        colunas: Array.isArray(columns) && columns?.length > 0 ? columns : undefined,
        tipo,
        modelo,
      },
    };
  }

  try {
    const { data } = yield call(gerarRelatorio, ProgramaCodigo.InformacoesNutricionais, query, tipo);

    if (tipo === TipoRelatorio.visualizar) {
      const url = URL.createObjectURL(
        new Blob([data], {
          type: 'application/pdf',
        }),
      );

      if (url) {
        yield put(gerarRelatorioInformacoesNutricionaisSucesso());
        window.open(url, '_blank');
      }
    } else {
      yield put(
        gerarRelatorioInformacoesNutricionaisSucesso({
          titulo: 'Pedido de impressão salvo.',
          mensagem: 'Seu relatório está sendo gerado.',
        }),
      );
    }
  } catch (e) {
    yield put(
      gerarRelatorioInformacoesNutricionaisErro('Ocorreu um erro ao tentar gerar o relatório. Tente novamente mais tarde.'),
    );
  }
}


function* ordenarInformacoesNutricionais({ payload }) {
  if (payload.programaId === ProgramaCodigo.InformacoesNutricionais) {
    yield call(listarTodosInformacoesNutricionais);
  }
}

function* mudarLimitePaginacao({ payload }) {
  if (payload.programaId === ProgramaCodigo.InformacoesNutricionais) {
    yield call(listarTodosInformacoesNutricionais);
  }
}

export function* watchListarInformacoesNutricionais() {
  yield takeLatest(LISTAR_INFORMACOES_NUTRICIONAIS, listarTodosInformacoesNutricionais);
}

export function* watchFiltrarInformacoesNutricionais() {
  yield takeLatest(FILTRAR_INFORMACOES_NUTRICIONAIS, listarTodosInformacoesNutricionais);
}

export function* watchRelatorioInformacoesNutricionais() {
  yield takeLatest(GERAR_RELATORIO_INFORMACOES_NUTRICIONAIS, relatorioInformacoesNutricionais);
}

export function* watchOrdenarInformacoesNutricionais() {
  yield takeLatest(SET_TABLE_ORDER, ordenarInformacoesNutricionais);
}

export function* watchLimitePaginacao() {
  yield takeLatest(SET_LIMIT_PAGE, mudarLimitePaginacao);
}

function* informacoesNutricionaisSaga() {
  yield all([
    fork(watchListarInformacoesNutricionais),
    fork(watchFiltrarInformacoesNutricionais),
    fork(watchOrdenarInformacoesNutricionais),
    fork(watchRelatorioInformacoesNutricionais),
    fork(watchLimitePaginacao),
  ]);
}

export default informacoesNutricionaisSaga;
