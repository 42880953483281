import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';

import { ProgramaCodigo } from '../../../constants/enums/programa-codigo.enum';
import { FILTRAR_MESAS_COMANDAS,GERAR_RELATORIO_MESAS_COMANDAS, LISTAR_MESAS_COMANDAS } from './constants';
import { obterMesasComandasAsync } from '../requests';
import { 
  gerarRelatorioMesasComandasErro,
  gerarRelatorioMesasComandasSucesso,
  obterMesasComandasErro, obterMesasComandasSucesso } from './actions';
import { SET_LIMIT_PAGE, SET_TABLE_ORDER } from '../../../redux/table/constants';
import { montarFiltroParaApi } from '../../../utils/api-filter';
import { gerarRelatorio } from '../../../services/requests/relatorio';
import { TipoRelatorio } from '../../../constants/enums/impressao.enum';

function* listarTodosMesasComandas() {
  try {
    const filtro = yield select((state) => state.MesasComandas.filtros);
    const preferencias = yield select((state) => state.TableConfig[ProgramaCodigo.MesasComandas]);

    let query = { filtro: montarFiltroParaApi(filtro) };

    if (preferencias) {
      query.ordenacao = {
        campo: preferencias?.ordenarPor || 'id',
        ordem: preferencias?.ordem?.toUpperCase() || 'ASC',
      };
      query.paginacao = {
        pagina: preferencias?.page || 1,
        limite: preferencias?.limit || 50,
      };
    }

    const { data } = yield call(obterMesasComandasAsync, query);
    yield put(obterMesasComandasSucesso(data));
  } catch (error) {
    yield put(obterMesasComandasErro(error.response?.data?.message || error.message));
  }
}

function* relatorioMesasComandas() {
  const { filtros, relatorio } = yield select((state) => state.MesasComandas);
  const preferencias = yield select((state) => state.TableConfig[ProgramaCodigo.MesasComandas]);
  const { modelo, tipo, comFiltro, colunas } = relatorio;
  const columns = colunas?.map((coluna) => coluna.value);
  let query = {};

  if (comFiltro) {
    query = {
      filtro: {
        ...montarFiltroParaApi(filtros),
        colunas: Array.isArray(columns) && columns?.length > 0 ? columns : undefined,
        tipo,
        modelo,
      },
      ordenacao: {
        campo: preferencias?.ordenarPor || 'id',
        ordem: preferencias?.ordem?.toUpperCase() || 'ASC',
      },
    };
  } else {
    query = {
      filtro: {
        colunas: Array.isArray(columns) && columns?.length > 0 ? columns : undefined,
        tipo,
        modelo,
      },
    };
  }

  try {
    const { data } = yield call(gerarRelatorio, ProgramaCodigo.MesasComandas, query, tipo);

    if (tipo === TipoRelatorio.visualizar) {
      const url = URL.createObjectURL(
        new Blob([data], {
          type: 'application/pdf',
        }),
      );

      if (url) {
        yield put(gerarRelatorioMesasComandasSucesso());
        window.open(url, '_blank');
      }
    } else {
      yield put(
        gerarRelatorioMesasComandasSucesso({
          titulo: 'Pedido de impressão salvo.',
          mensagem: 'Seu relatório está sendo gerado.',
        }),
      );
    }
  } catch (e) {
    yield put(
      gerarRelatorioMesasComandasErro('Ocorreu um erro ao tentar gerar o relatório. Tente novamente mais tarde.'),
    );
  }
}

function* ordenarMesasComandas({ payload }) {
  if (payload.programaId === ProgramaCodigo.MesasComandas) {
    yield call(listarTodosMesasComandas);
  }
}

function* mudarLimitePaginacao({ payload }) {
  if (payload.programaId === ProgramaCodigo.MesasComandas) {
    yield call(listarTodosMesasComandas);
  }
}

export function* watchListarMesasComandas() {
  yield takeLatest(LISTAR_MESAS_COMANDAS, listarTodosMesasComandas);
}

export function* watchFiltrarMesasComandas() {
  yield takeLatest(FILTRAR_MESAS_COMANDAS, listarTodosMesasComandas);
}

export function* watchRelatorioMesasComandas() {
  yield takeLatest(GERAR_RELATORIO_MESAS_COMANDAS, relatorioMesasComandas);
}

export function* watchOrdenarMesasComandas() {
  yield takeLatest(SET_TABLE_ORDER, ordenarMesasComandas);
}

export function* watchLimitePaginacao() {
  yield takeLatest(SET_LIMIT_PAGE, mudarLimitePaginacao);
}

function* mesasComandasSaga() {
  yield all([
    fork(watchListarMesasComandas),
    fork(watchFiltrarMesasComandas),
    fork(watchRelatorioMesasComandas),
    fork(watchOrdenarMesasComandas),
    fork(watchLimitePaginacao),
  ]);
}

export default mesasComandasSaga;
