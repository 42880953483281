import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';

import { ProgramaCodigo } from '../../../constants/enums/programa-codigo.enum';
import {
  FILTRAR_FORMAS_PAGAMENTOS,
  GERAR_RELATORIO_FORMAS_PAGAMENTOS,
  LISTAR_FORMAS_PAGAMENTOS,
} from './constants';
import { obterFormasPagamentosAsync } from '../requests';
import {
  gerarRelatorioFormasPagamentosErro,
  gerarRelatorioFormasPagamentosSucesso,
  obterFormasPagamentosErro,
  obterFormasPagamentosSucesso,
} from './actions';
import { SET_LIMIT_PAGE, SET_TABLE_ORDER } from '../../../redux/table/constants';
import { montarFiltroParaApi } from '../../../utils/api-filter';
import { gerarRelatorio } from '../../../services/requests/relatorio';
import { TipoRelatorio } from '../../../constants/enums/impressao.enum';

function* listarTodosFormasPagamentos() {
  try {
    const filtro = yield select((state) => state.FormasPagamentos.filtros);
    const preferencias = yield select((state) => state.TableConfig[ProgramaCodigo.FormasPagamentos]);

    let query = { filtro: montarFiltroParaApi(filtro) };

    if (preferencias) {
      query.ordenacao = {
        campo: preferencias?.ordenarPor || 'nome',
        ordem: preferencias?.ordem?.toUpperCase() || 'ASC',
      };
      query.paginacao = {
        pagina: preferencias?.page || 1,
        limite: preferencias?.limit || 50,
      };
    }
    const { data } = yield call(obterFormasPagamentosAsync, query);
    yield put(obterFormasPagamentosSucesso(data));
  } catch (error) {
    yield put(obterFormasPagamentosErro(error.response?.data?.message || error.message));
  }
}

function* relatorioFormasPagamentos() {
  const { filtros, relatorio } = yield select((state) => state.FormasPagamentos);
  const preferencias = yield select((state) => state.TableConfig[ProgramaCodigo.FormasPagamentos]);
  const { modelo, tipo, comFiltro, colunas } = relatorio;
  const columns = colunas?.map((coluna) => coluna.value);
  let query = {};

  if (comFiltro) {
    query = {
      filtro: {
        ...montarFiltroParaApi(filtros),
        colunas: Array.isArray(columns) && columns?.length > 0 ? columns : undefined,
        // tipo, TODO: esta dando conflito com o campo "tipo" da tabela com o "tipo" do relatorio, arrumar
        modelo,
      },
      ordenacao: {
        campo: preferencias?.ordenarPor || 'nome',
        ordem: preferencias?.ordem?.toUpperCase() || 'ASC',
      },
    };
  } else {
    query = {
      filtro: {
        colunas: Array.isArray(columns) && columns?.length > 0 ? columns : undefined,
        tipo,
        modelo,
      },
    };
  }

  try {
    const { data } = yield call(gerarRelatorio, ProgramaCodigo.FormasPagamentos, query, tipo);

    if (tipo === TipoRelatorio.visualizar) {
      const url = URL.createObjectURL(
        new Blob([data], {
          type: 'application/pdf',
        }),
      );

      if (url) {
        yield put(gerarRelatorioFormasPagamentosSucesso());
        window.open(url, '_blank');
      }
    } else {
      yield put(
        gerarRelatorioFormasPagamentosSucesso({
          titulo: 'Pedido de impressão salvo.',
          mensagem: 'Seu relatório está sendo gerado.',
        }),
      );
    }
  } catch (e) {
    yield put(
      gerarRelatorioFormasPagamentosErro(
        'Ocorreu um erro ao tentar gerar o relatório. Tente novamente mais tarde.',
      ),
    );
  }
}

function* ordenarFormasPagamentos({ payload }) {
  if (payload.programaId === ProgramaCodigo.FormasPagamentos) {
    yield call(listarTodosFormasPagamentos);
  }
}

function* mudarLimitePaginacao({ payload }) {
  if (payload.programaId === ProgramaCodigo.FormasPagamentos) {
    yield call(listarTodosFormasPagamentos);
  }
}

export function* watchListarFormasPagamentos() {
  yield takeLatest(LISTAR_FORMAS_PAGAMENTOS, listarTodosFormasPagamentos);
}

export function* watchFiltrarFormasPagamentos() {
  yield takeLatest(FILTRAR_FORMAS_PAGAMENTOS, listarTodosFormasPagamentos);
}

export function* watchRelatorioFormasPagamentos() {
  yield takeLatest(GERAR_RELATORIO_FORMAS_PAGAMENTOS, relatorioFormasPagamentos);
}

export function* watchOrdenarFormasPagamentos() {
  yield takeLatest(SET_TABLE_ORDER, ordenarFormasPagamentos);
}

export function* watchLimitePaginacao() {
  yield takeLatest(SET_LIMIT_PAGE, mudarLimitePaginacao);
}

function* FormasPagamentosSaga() {
  yield all([
    fork(watchListarFormasPagamentos),
    fork(watchFiltrarFormasPagamentos),
    fork(watchRelatorioFormasPagamentos),
    fork(watchOrdenarFormasPagamentos),
    fork(watchLimitePaginacao),
  ]);
}

export default FormasPagamentosSaga;
