import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';

import { FILTRAR_EMPRESAS, LISTAR_EMPRESAS, GERAR_RELATORIO_EMPRESAS } from './constants';

import { 
  gerarRelatorioEmpresasErro,
  gerarRelatorioEmpresasSucesso,
  obterTodasEmpresasSucesso, 
  obterTodasEmpresasErro } from './actions';
import { obterEmpresasAsync } from '../requests';
import { ProgramaCodigo } from '../../../constants/enums/programa-codigo.enum';
import { SET_LIMIT_PAGE, SET_TABLE_ORDER } from '../../../redux/table/constants';
import { montarFiltroParaApi } from '../../../utils/api-filter';
import { gerarRelatorio } from '../../../services/requests/relatorio';
import { TipoRelatorio } from '../../../constants/enums/impressao.enum';

function* listarTodasEmpresas() {
  try {
    const filtro = yield select((state) => state.Empresas.filtros);
    const preferencias = yield select((state) => state.TableConfig[ProgramaCodigo.Empresas]);
    let query = { filtro: montarFiltroParaApi(filtro) };

    if (preferencias) {
      query.ordenacao = {
        campo: preferencias?.ordenarPor || 'razaoSocial',
        ordem: preferencias?.ordem?.toUpperCase() || 'ASC',
      };
      query.paginacao = {
        pagina: preferencias?.page || 1,
        limite: preferencias?.limit || 50,
      };
    }

    const { data } = yield call(obterEmpresasAsync, query);
    yield put(obterTodasEmpresasSucesso(data));
  } catch (error) {
    console.error(error);
    yield put(obterTodasEmpresasErro(error.response?.data?.message || error.message));
  }
}

function* relatorioEmpresas() {
  const { filtros, relatorio } = yield select((state) => state.Empresas);
  const preferencias = yield select((state) => state.TableConfig[ProgramaCodigo.Empresas]);
  const { modelo, tipo, comFiltro, colunas } = relatorio;
  const columns = colunas?.map((coluna) => coluna.value);
  let query = {};

  if (comFiltro) {
    query = {
      filtro: {
        ...montarFiltroParaApi(filtros),
        colunas: Array.isArray(columns) && columns?.length > 0 ? columns : undefined,
        tipo,
        modelo,
      },
      ordenacao: {
        campo: preferencias?.ordenarPor || 'razaoSocial',
        ordem: preferencias?.ordem?.toUpperCase() || 'ASC',
      },
    };
  } else {
    query = {
      filtro: {
        colunas: Array.isArray(columns) && columns?.length > 0 ? columns : undefined,
        tipo,
        modelo,
      },
    };
  }

  try {
    const { data } = yield call(gerarRelatorio, ProgramaCodigo.Empresas, query, tipo);

    if (tipo === TipoRelatorio.visualizar) {
      const url = URL.createObjectURL(
        new Blob([data], {
          type: 'application/pdf',
        }),
      );

      if (url) {
        yield put(gerarRelatorioEmpresasSucesso());
        window.open(url, '_blank');
      }
    } else {
      yield put(
        gerarRelatorioEmpresasSucesso({
          titulo: 'Pedido de impressão salvo.',
          mensagem: 'Seu relatório está sendo gerado.',
        }),
      );
    }
  } catch (e) {
    yield put(
      gerarRelatorioEmpresasErro('Ocorreu um erro ao tentar gerar o relatório. Tente novamente mais tarde.'),
    );
  }
}

function* ordenarEmpresa({ payload }) {
  if (payload.programaId === ProgramaCodigo.Empresas) {
    yield call(listarTodasEmpresas);
  }
}

function* mudarLimitePaginacao({ payload }) {
  if (payload.programaId === ProgramaCodigo.Empresas) {
    yield call(listarTodasEmpresas);
  }
}

export function* watchListarEmpresas() {
  yield takeLatest(LISTAR_EMPRESAS, listarTodasEmpresas);
}

export function* watchFiltrarEmpresas() {
  yield takeLatest(FILTRAR_EMPRESAS, listarTodasEmpresas);
}

export function* watchRelatorioEmpresas() {
  yield takeLatest(GERAR_RELATORIO_EMPRESAS, relatorioEmpresas);
}

export function* watchOrdenarEmpresas() {
  yield takeLatest(SET_TABLE_ORDER, ordenarEmpresa);
}

export function* watchLimitePaginacao() {
  yield takeLatest(SET_LIMIT_PAGE, mudarLimitePaginacao);
}

function* empresasSaga() {
  yield all([
    fork(watchListarEmpresas),
    fork(watchFiltrarEmpresas),
    fork(watchRelatorioEmpresas),
    fork(watchOrdenarEmpresas),
    fork(watchLimitePaginacao),
  ]);
}

export default empresasSaga;
