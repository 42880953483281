import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';

import { FILTRAR_USUARIOS, LISTAR_USUARIOS, GERAR_RELATORIO_USUARIOS } from './constants';

import { 
  gerarRelatorioUsuariosErro,
  gerarRelatorioUsuariosSucesso,
  obterUsuariosSucesso, 
  obterUsuariosErro } from './actions';
import { SET_TABLE_ORDER, SET_LIMIT_PAGE } from '../../../redux/table/constants';
import { ProgramaCodigo } from '../../../constants/enums/programa-codigo.enum';
import { obterUsuariosAsync } from '../requests';
import { montarFiltroParaApi } from '../../../utils/api-filter';
import { gerarRelatorio } from '../../../services/requests/relatorio';
import { TipoRelatorio } from '../../../constants/enums/impressao.enum';

function* listarTodosUsuarios() {
  try {
    const filtro = yield select((state) => state.Usuario.filtros);
    const preferencias = yield select((state) => state.TableConfig[ProgramaCodigo.Usuarios]);
    let query = { filtro: montarFiltroParaApi(filtro) };

    if (preferencias) {
      query.ordenacao = {
        campo: preferencias?.ordenarPor || 'nome',
        ordem: preferencias?.ordem?.toUpperCase() || 'ASC',
      };
      query.paginacao = {
        pagina: preferencias?.page || 1,
        limite: preferencias?.limit || 50,
      };
    }

    const { data } = yield call(obterUsuariosAsync, query);
    yield put(obterUsuariosSucesso(data));
  } catch (error) {
    yield put(obterUsuariosErro(error.response?.data?.message || error.message));
  }
}

function* relatorioUsuarios() {
  const { filtros, relatorio } = yield select((state) => state.Usuario);
  const preferencias = yield select((state) => state.TableConfig[ProgramaCodigo.Usuarios]);
  const { modelo, tipo, comFiltro, colunas } = relatorio;
  const columns = colunas?.map((coluna) => coluna.value);
  let query = {};

  if (comFiltro) {
    query = {
      filtro: {
        ...montarFiltroParaApi(filtros),
        colunas: Array.isArray(columns) && columns?.length > 0 ? columns : undefined,
        tipo,
        modelo,
      },
      ordenacao: {
        campo: preferencias?.ordenarPor || 'nome',
        ordem: preferencias?.ordem?.toUpperCase() || 'ASC',
      },
    };
  } else {
    query = {
      filtro: {
        colunas: Array.isArray(columns) && columns?.length > 0 ? columns : undefined,
        tipo,
        modelo,
      },
    };
  }

  try {
    const { data } = yield call(gerarRelatorio, ProgramaCodigo.Usuarios, query, tipo);

    if (tipo === TipoRelatorio.visualizar) {
      const url = URL.createObjectURL(
        new Blob([data], {
          type: 'application/pdf',
        }),
      );

      if (url) {
        yield put(gerarRelatorioUsuariosSucesso());
        window.open(url, '_blank');
      }
    } else {
      yield put(
        gerarRelatorioUsuariosSucesso({
          titulo: 'Pedido de impressão salvo.',
          mensagem: 'Seu relatório está sendo gerado.',
        }),
      );
    }
  } catch (e) {
    yield put(
      gerarRelatorioUsuariosErro('Ocorreu um erro ao tentar gerar o relatório. Tente novamente mais tarde.'),
    );
  }
}


function* ordenarUsuarios({ payload }) {
  if (payload.programaId === ProgramaCodigo.Usuarios) {
    yield call(listarTodosUsuarios);
  }
}

function* mudarLimitePaginacao({ payload }) {
  if (payload.programaId === ProgramaCodigo.Usuarios) {
    yield call(listarTodosUsuarios);
  }
}

export function* watchLimitePaginacao() {
  yield takeLatest(SET_LIMIT_PAGE, mudarLimitePaginacao);
}

export function* watchListarUsuarios() {
  yield takeLatest(LISTAR_USUARIOS, listarTodosUsuarios);
}

export function* watchRelatorioUsuarios() {
  yield takeLatest(GERAR_RELATORIO_USUARIOS, relatorioUsuarios);
}

export function* watchFiltrarUsuarios() {
  yield takeLatest(FILTRAR_USUARIOS, listarTodosUsuarios);
}

export function* watchOrdenarUsuarios() {
  yield takeLatest(SET_TABLE_ORDER, ordenarUsuarios);
}

function* usuarioSaga() {
  yield all([
    fork(watchListarUsuarios),
    fork(watchFiltrarUsuarios),
    fork(watchRelatorioUsuarios),
    fork(watchOrdenarUsuarios),
    fork(watchLimitePaginacao),
  ]);
}

export default usuarioSaga;
