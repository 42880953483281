import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';

import { ProgramaCodigo } from '../../../constants/enums/programa-codigo.enum';
import { FILTRAR_GRUPOS_CENTROS_CUSTOS, GERAR_RELATORIO_GRUPOS_CENTROS_CUSTOS, LISTAR_GRUPOS_CENTROS_CUSTOS } from './constants';
import { obterGruposCentrosCustosAsync } from '../requests';
import { 
  gerarRelatorioGruposCentrosCustosErro,
  gerarRelatorioGruposCentrosCustosSucesso,
  obterGruposCentrosCustosErro, 
  obterGruposCentrosCustosSucesso } from './actions';
import { SET_LIMIT_PAGE, SET_TABLE_ORDER } from '../../../redux/table/constants';
import { montarFiltroParaApi } from '../../../utils/api-filter';
import { gerarRelatorio } from '../../../services/requests/relatorio';
import { TipoRelatorio } from '../../../constants/enums/impressao.enum';

function* listarTodosGruposCentrosCustos() {
  try {
    const filtro = yield select((state) => state.GruposCentrosCustos.filtros);
    const preferencias = yield select((state) => state.TableConfig[ProgramaCodigo.GruposCentrosCustos]);

    let query = { filtro: montarFiltroParaApi(filtro) };

    if (preferencias) {
      query.ordenacao = {
        campo: preferencias?.ordenarPor || 'nome',
        ordem: preferencias?.ordem?.toUpperCase() || 'ASC',
      };
      query.paginacao = {
        pagina: preferencias?.page || 1,
        limite: preferencias?.limit || 50,
      };
    }

    const { data } = yield call(obterGruposCentrosCustosAsync, query);
    yield put(obterGruposCentrosCustosSucesso(data));
  } catch (error) {
    yield put(obterGruposCentrosCustosErro(error.response?.data?.message || error.message));
  }
}
function* relatorioGruposCentrosCustos() {
  const { filtros, relatorio } = yield select((state) => state.GruposCentrosCustos);
  const preferencias = yield select((state) => state.TableConfig[ProgramaCodigo.GruposCentrosCustos]);
  const { modelo, tipo, comFiltro, colunas } = relatorio;
  const columns = colunas?.map((coluna) => coluna.value);
  let query = {};

  if (comFiltro) {
    query = {
      filtro: {
        ...montarFiltroParaApi(filtros),
        colunas: Array.isArray(columns) && columns?.length > 0 ? columns : undefined,
        tipo,
        modelo,
      },
      ordenacao: {
        campo: preferencias?.ordenarPor || 'nome',
        ordem: preferencias?.ordem?.toUpperCase() || 'ASC',
      },
    };
  } else {
    query = {
      filtro: {
        colunas: Array.isArray(columns) && columns?.length > 0 ? columns : undefined,
        tipo,
        modelo,
      },
    };
  }

  try {
    const { data } = yield call(gerarRelatorio, ProgramaCodigo.GruposCentrosCustos, query, tipo);

    if (tipo === TipoRelatorio.visualizar) {
      const url = URL.createObjectURL(
        new Blob([data], {
          type: 'application/pdf',
        }),
      );

      if (url) {
        yield put(gerarRelatorioGruposCentrosCustosSucesso());
        window.open(url, '_blank');
      }
    } else {
      yield put(
        gerarRelatorioGruposCentrosCustosSucesso({
          titulo: 'Pedido de impressão salvo.',
          mensagem: 'Seu relatório está sendo gerado.',
        }),
      );
    }
  } catch (e) {
    yield put(
      gerarRelatorioGruposCentrosCustosErro('Ocorreu um erro ao tentar gerar o relatório. Tente novamente mais tarde.'),
    );
  }
}

function* ordenarGruposCentrosCustos({ payload }) {
  if (payload.programaId === ProgramaCodigo.GruposCentrosCustos) {
    yield call(listarTodosGruposCentrosCustos);
  }
}

function* mudarLimitePaginacao({ payload }) {
  if (payload.programaId === ProgramaCodigo.GruposCentrosCustos) {
    yield call(listarTodosGruposCentrosCustos);
  }
}

export function* watchListarGruposCentrosCustos() {
  yield takeLatest(LISTAR_GRUPOS_CENTROS_CUSTOS, listarTodosGruposCentrosCustos);
}

export function* watchFiltrarGruposCentrosCustos() {
  yield takeLatest(FILTRAR_GRUPOS_CENTROS_CUSTOS, listarTodosGruposCentrosCustos);
}

export function* watchRelatorioGruposCentrosCustos() {
  yield takeLatest(GERAR_RELATORIO_GRUPOS_CENTROS_CUSTOS, relatorioGruposCentrosCustos);
}

export function* watchOrdenarGruposCentrosCustos() {
  yield takeLatest(SET_TABLE_ORDER, ordenarGruposCentrosCustos);
}

export function* watchLimitePaginacao() {
  yield takeLatest(SET_LIMIT_PAGE, mudarLimitePaginacao);
}

function* gruposCentrosCustosSaga() {
  yield all([
    fork(watchListarGruposCentrosCustos),
    fork(watchFiltrarGruposCentrosCustos),
    fork(watchRelatorioGruposCentrosCustos),
    fork(watchOrdenarGruposCentrosCustos),
    fork(watchLimitePaginacao),
  ]);
}

export default gruposCentrosCustosSaga;
