// @flow
import { all } from 'redux-saga/effects';
import relatorioSaga from '../components/tabelaRelatorios/store/saga';
import aliquotasImpostosServicosSaga from '../pages/aliquotas-impostos-servicos/store/saga';
import alvosSaga from '../pages/alvos/store/saga';
import armadilhasSaga from '../pages/armadilhas/store/saga';
import atividadesControleProdutividadeSaga from '../pages/atividade-controle-produtividade/store/saga';
import atividadesSaga from '../pages/atividades/store/saga';
import authSaga from '../pages/auth/store/saga';
import caixaBancosSaga from '../pages/caixa-bancos/store/saga';
import camarasFriaSaga from '../pages/camaras-fria/store/saga';
import categoriasProdutosAgroSaga from '../pages/categoria-produtos-agro/store/saga';
import centrosCustosSaga from '../pages/centros-custos/store/saga';
import cestSaga from '../pages/cest/store/saga';
import CFOPsSaga from '../pages/cfop/store/saga';
import cidadesSaga from '../pages/cidades/store/saga';
import classesProdutosSaga from '../pages/classes-produtos/store/saga';
import CNAEsSaga from '../pages/cnae/store/saga';
import codigosBarrasEANSaga from '../pages/codigos-barras-ean/store/saga';
import conceitosQualidadeSaga from '../pages/conceitos-qualidade/store/saga';
import configuracoesSaga from '../pages/configuracoes/store/saga';
import contasPagarSaga from '../pages/contas-pagar/store/saga';
import contasReceberSaga from '../pages/contas-receber/store/saga';
import contasSaga from '../pages/contas/store/saga';
import contratosSaga from '../pages/contratos/store/saga';
import coresProdutosSaga from '../pages/cores-produtos/store/saga';
import cotacoesSaga from '../pages/cotacoes/store/saga';
import cultivosProtegidosSaga from '../pages/cultivos-protegidos/store/saga';
import culturasSaga from '../pages/culturas/store/saga';
import danosDefeitosSaga from '../pages/danos-defeitos/store/saga';
import departamentoProdutoSaga from '../pages/departamentos-produtos/store/saga';
import depositosSaga from '../pages/depositos/store/saga';
import empresasSaga from '../pages/empresas/store/saga';
import entradaSaidas from '../pages/entrada-frutas/store/saga';
import entradasSaidasProdutosSaga from '../pages/entrada-saida-produtos/store/saga';
import equipesAtividadesSaga from '../pages/equipes-atividades/store/saga';
import estadosSaga from '../pages/estados/store/saga';
import estagiosFenologicosSaga from '../pages/estagios-fenologicos/store/saga';
import formasPagamentosSaga from '../pages/formas-pagamentos/store/saga';
import gruposAtividadesSaga from '../pages/grupos-atividades/store/saga';
import gruposCentrosCustosSaga from '../pages/grupos-centros-custos/store/saga';
import gruposEconomicosPessoasSaga from '../pages/grupos-economicos-pessoas/store/saga';
import gruposFinanceirosSaga from '../pages/grupos-financeiros/store/saga';
import gruposMaquinasImplementosSaga from '../pages/grupos-maquinas-implementos/store/saga';
import gruposPessoasSaga from '../pages/grupos-pessoas/store/saga';
import grupoProdutoSaga from '../pages/grupos-produtos/store/saga';
import gruposQuimicosSaga from '../pages/grupos-quimicos/store/saga';
import GrupoServicoSaga from '../pages/grupos-servico/store/saga';
import historicosPadraoSaga from '../pages/historicos-padrao/store/saga';
import informacoesNutricionaisSaga from '../pages/informacoes-nutricionais/store/saga';
import integradoresSaga from '../pages/integradores/store/saga';
import lancamentosAtividades from '../pages/lancamentos-atividades/store/saga';
import lancamentosCustosSaga from '../pages/lancamentos-custos/store/saga';
import listasPrecosSaga from '../pages/listas-precos/store/saga';
import ManutencaoMaquinasVeiculos from '../pages/manutencao-maquinas-veiculos/store/saga';
import maquinasImplementosSaga from '../pages/maquinas-implementos/store/saga';
import marcasProdutosSaga from '../pages/marcas-produtos/store/saga';
import marcasVeiculos from '../pages/marcas-veiculos/store/saga';
import mesasComandasSaga from '../pages/mesas-comandas/store/saga';
import modelosEtiquetas from '../pages/modelos-etiquetas/store/saga';
import modelosRelatoriosSaga from '../pages/modelos-relatorios/store/saga';
import moduloSaga from '../pages/modulos/store/saga';
import moedasSaga from '../pages/moedas/store/saga';
import motivosCancelamentosContratosSaga from '../pages/motivos-cancelamentos-contratos/store/saga';
import movimentacoesProdutos from '../pages/movimentacao-produtos/store/saga';
import naturezaOperacoesSaga from '../pages/natureza-operacao/store/saga';
import NCMsSaga from '../pages/ncm/store/saga';
import notasEntradasSaga from '../pages/notas-entradas/store/saga';
import notasFiscaisConsumidorSaga from '../pages/notas-fiscais-consumidor/store/saga';
import notasFiscaisServicosSaga from '../pages/notas-fiscais-servico/store/saga';
import notasFiscaisSaga from '../pages/notas-fiscais/store/saga';
import observacoesAtividadesSaga from '../pages/observacoes-atividades/store/saga';
import ocorrenciasFinanceirasSaga from '../pages/ocorrencias-financeiras/store/saga';
import opcoesCadastraisSaga from '../pages/opcoes-cadastrais/store/saga';
import Orcamentos from '../pages/orcamentos/store/saga';
import ordensCompraSaga from '../pages/ordens-compra/store/saga';
import organizacoesSaga from '../pages/organizacoes/store/saga';
import paisesSaga from '../pages/paises/store/saga';
import parametrosSaga from '../pages/parametros/store/saga';
import Pedidos from '../pages/pedidos/store/saga';
import perfilAcessoSaga from '../pages/perfil-acesso/store/saga';
import permissoesSaga from '../pages/permissoes/store/saga';
import pessoasSaga from '../pages/pessoas/store/saga';
import planoContasSaga from '../pages/plano-contas/store/saga';
import portaEnxertosSaga from '../pages/porta-enxertos/store/saga';
import previsoesColheitaSaga from '../pages/previsoes-colheita/store/saga';
import principiosAtivosSaga from '../pages/principios-ativo-produto/store/saga';
import produtosSaga from '../pages/produtos/store/saga';
import programaSaga from '../pages/programas/store/saga';
import promocoesSaga from '../pages/promocoes/store/saga';
import propriedadesSaga from '../pages/propriedades/store/saga';
import recomendacoesTecnicasSaga from '../pages/recomendacoes-tecnicas/store/saga';
import RegistroEtiquetasEntradaFrutasSaga from '../pages/registro-etiquetas-entrada-frutas/store/saga';
import registrosClimaticosSaga from '../pages/registros-climaticos/store/saga';
import relatoriosCadernoCampoSaga from '../pages/relatorio-caderno-campo/store/saga';
import relatoriosCustosSaga from '../pages/relatorios-custos/store/saga';
import relatoriosEstoqueSaga from '../pages/relatorios-estoque/store/saga';
import relatoriosLogSaga from '../pages/relatorios-logs/store/saga';
import relatoriosMonitoramentoCarenciaSaga from '../pages/relatorios-monitoramento-carencia/store/saga';
import revendaSaga from '../pages/revenda/store/saga';
import safrasSaga from '../pages/safras/store/saga';
import segmentosSaga from '../pages/segmentos/store/saga';
import ServicoSaga from '../pages/servicos/store/saga';
import SetoresMesas from '../pages/setores-mesas/store/saga';
import sistemasConducaoSaga from '../pages/sistemas-conducao/store/saga';
import sistemasIrrigacaoSaga from '../pages/sistemas-irrigacao/store/saga';
import sistemasProducaoSaga from '../pages/sistemas-producao/store/saga';
import solucoesSaga from '../pages/solucoes/store/saga';
import subGruposFinanceirosSaga from '../pages/sub-grupos-financeiros/store/saga';
import subGrupoProdutoSaga from '../pages/sub-grupos-produtos/store/saga';
import tamanhosProdutosAgroSaga from '../pages/tamanho-produtos-agro/store/saga';
import tamanhosProdutosSaga from '../pages/tamanho-produtos/store/saga';
import tiposCobrancasSaga from '../pages/tipos-cobrancas/store/saga';
import transferenciaDepositoSaga from '../pages/transferencia-depositos/store/saga';
import transferenciasCaixaBancosSaga from '../pages/transferencias-caixa-bancos/store/saga';
import tributacoesFederaisPisCofins from '../pages/tributacao-federal-pis-cofins/store/saga';
import tributacoesEstaduaisSaga from '../pages/tributacoes-estaduais/store/saga';
import tributacoesFederaisIpiSaga from '../pages/tributacoes-federais-ipi/store/saga';
import tributacoesServicosSaga from '../pages/tributacoes-servicos/store/saga';
import unidadesMedidaSaga from '../pages/unidades-medidas-produtos/store/saga';
import unidadesProdutivasSaga from '../pages/unidades-produtivas/store/saga';
import usuarioSaga from '../pages/usuarios/store/saga';
import valoresMaoObraSaga from '../pages/valores-mao-obra/store/saga';
import variedadesClonesSaga from '../pages/variedades-clones/store/saga';
import variedadesSaga from '../pages/variedades/store/saga';
import vasilhamesSaga from '../pages/vasilhames/store/saga';
import veiculos from '../pages/veiculos/store/saga';
import vendasColheitasSaga from '../pages/vendas-colheitas/store/saga';
import visitasTecnicas from '../pages/visitas-tecnicas/store/saga';
import appMenuSaga from './appMenu/saga';
import layoutSaga from './layout/saga';
import preferencesSaga from './table/saga';

export default function* rootSaga(getState) {
  yield all([
    authSaga(),
    layoutSaga(),
    appMenuSaga(),
    preferencesSaga(),
    usuarioSaga(),
    perfilAcessoSaga(),
    programaSaga(),
    moduloSaga(),
    solucoesSaga(),
    gruposEconomicosPessoasSaga(),
    parametrosSaga(),
    segmentosSaga(),
    organizacoesSaga(),
    permissoesSaga(),
    paisesSaga(),
    estadosSaga(),
    gruposPessoasSaga(),
    empresasSaga(),
    pessoasSaga(),
    CNAEsSaga(),
    cidadesSaga(),
    NCMsSaga(),
    opcoesCadastraisSaga(),
    CFOPsSaga(),
    sistemasIrrigacaoSaga(),
    sistemasProducaoSaga(),
    cultivosProtegidosSaga(),
    revendaSaga(),
    portaEnxertosSaga(),
    variedadesSaga(),
    variedadesClonesSaga(),
    cestSaga(),
    culturasSaga(),
    grupoProdutoSaga(),
    subGrupoProdutoSaga(),
    marcasProdutosSaga(),
    unidadesMedidaSaga(),
    departamentoProdutoSaga(),
    classesProdutosSaga(),
    gruposQuimicosSaga(),
    estagiosFenologicosSaga(),
    sistemasConducaoSaga(),
    gruposAtividadesSaga(),
    informacoesNutricionaisSaga(),
    coresProdutosSaga(),
    tamanhosProdutosSaga(),
    categoriasProdutosAgroSaga(),
    atividadesSaga(),
    tamanhosProdutosAgroSaga(),
    alvosSaga(),
    gruposCentrosCustosSaga(),
    centrosCustosSaga(),
    principiosAtivosSaga(),
    produtosSaga(),
    propriedadesSaga(),
    observacoesAtividadesSaga(),
    equipesAtividadesSaga(),
    armadilhasSaga(),
    danosDefeitosSaga(),
    lancamentosAtividades(),
    registrosClimaticosSaga(),
    relatorioSaga(),
    atividadesControleProdutividadeSaga(),
    gruposMaquinasImplementosSaga(),
    maquinasImplementosSaga(),
    unidadesProdutivasSaga(),
    recomendacoesTecnicasSaga(),
    previsoesColheitaSaga(),
    safrasSaga(),
    tributacoesEstaduaisSaga(),
    tributacoesFederaisIpiSaga(),
    conceitosQualidadeSaga(),
    integradoresSaga(),
    entradasSaidasProdutosSaga(),
    GrupoServicoSaga(),
    ServicoSaga(),
    contasSaga(),
    tributacoesFederaisPisCofins(),
    relatoriosEstoqueSaga(),
    camarasFriaSaga(),
    relatoriosCadernoCampoSaga(),
    depositosSaga(),
    vendasColheitasSaga(),
    relatoriosMonitoramentoCarenciaSaga(),
    lancamentosCustosSaga(),
    gruposFinanceirosSaga(),
    subGruposFinanceirosSaga(),
    tiposCobrancasSaga(),
    formasPagamentosSaga(),
    relatoriosCustosSaga(),
    valoresMaoObraSaga(),
    modelosEtiquetas(),
    marcasVeiculos(),
    visitasTecnicas(),
    veiculos(),
    naturezaOperacoesSaga(),
    ManutencaoMaquinasVeiculos(),
    notasFiscaisSaga(),
    ordensCompraSaga(),
    Pedidos(),
    Orcamentos(),
    notasEntradasSaga(),
    moedasSaga(),
    cotacoesSaga(),
    listasPrecosSaga(),
    promocoesSaga(),
    codigosBarrasEANSaga(),
    vasilhamesSaga(),
    entradaSaidas(),
    modelosRelatoriosSaga(),
    transferenciaDepositoSaga(),
    historicosPadraoSaga(),
    RegistroEtiquetasEntradaFrutasSaga(),
    planoContasSaga(),
    movimentacoesProdutos(),
    contasPagarSaga(),
    contasReceberSaga(),
    transferenciasCaixaBancosSaga(),
    caixaBancosSaga(),
    notasFiscaisConsumidorSaga(),
    notasFiscaisServicosSaga(),
    aliquotasImpostosServicosSaga(),
    SetoresMesas(),
    mesasComandasSaga(),
    configuracoesSaga(),
    ocorrenciasFinanceirasSaga(),
    motivosCancelamentosContratosSaga(),
    contratosSaga(),
    tributacoesServicosSaga(),
    relatoriosLogSaga(),
  ]);
}
